import React, {useState, useEffect} from 'react'
import { GenGet } from 'Admin/state/api'
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';
import axios from 'Admin/state/axios';
import { Alert, CircularProgress } from '@mui/material';
import { GenPost } from 'Loja/state/api';
import Toast from 'Admin/components/Toast';


const CarrocelPannel = () =>{
    const token = localStorage.getItem('token');
    const [scssMsg , setScssMsg] = useState(false);
    const GLOBAL_URL = process.env.REACT_APP_BASE_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [removerLoading, setRemoverLoading] = useState(false);
    const [images, setImages] = useState({});
    const [data, setData] = useState({
        imagem: null,
        descricao:'',
        titulo:'',
        subtitulo:''
      });
    
    const handleChange = (e) => {
        setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    };

    async function getCarrocel() {
        try {
            const response = await GenGet('/carrocel/index');
            setImages(response);
            setIsLoading(false);
            setRefresh(false);
        } catch (err) {
            
        }
    }
    useEffect(() => {
        getCarrocel();
    }, [])

    useEffect(() => {
        if(refresh){
            getCarrocel();
        }
    }, [refresh])
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);
        if(data.imagem){
            try {
            const response = await axios.post('/carrocel/store', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setScssMsg(response.data.message);
            setData({ imagem: null, descricao:'',titulo:'',subtitulo:'' })
            setRefresh(true);
            setTimeout(() => {
                setScssMsg(null);
              }, 3000);
              setSubmitLoading(false);
            } catch (error) {
                setScssMsg(error.data.message);
                setTimeout(() => {
                    setScssMsg(null);
                  }, 3000);
                  setSubmitLoading(false);
            }
            
        } else {
            setScssMsg("Insira uma imagem");
            setTimeout(() => {
                setScssMsg(null);
              }, 3000);
              setSubmitLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setRemoverLoading(true)
        try {
            const response = await GenPost(`api/carrocel/destroy/${id}`);
            setScssMsg(response.message);
            setTimeout(() => {
                setScssMsg(null);
              }, 3000);
              setRefresh(true);
              setRemoverLoading(false)
            } catch (error) {
                setRemoverLoading(false)
                setScssMsg(error.message);
                setTimeout(() => {
                    setScssMsg(null);
                  }, 3000);
            }
            
    };
    return(<>
        {isLoading?(
            <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
                <CircularProgress color="success" />
            </div>
        ):(
            <div className='text-[10pt]'>
            <h3 className='text-[14pt]'>Carrocel</h3>
            <p className='text-[9pt] -mt-2'>configurações do carrocel de sua loja.</p>
            <hr/>
            <h3 className="px-2 text-[12pt] m-0" >Imagens cadastradas</h3>
            <div className='flex flex-col gap-2'>
                {images ? (
                    <div className='px-4 pt-1 flex flex-col gap-2'>
                        {images.map((item, index) =>(
                            <div className='relative w-full flex flex-row gap-2  p-2 bg-slate-200 rounded-md ' key={index}>
                                <div className='w-[150px] h-[100px] overflow-hidden justify-center align-middle items-center rounded-md'>
                                    <img src={`${GLOBAL_URL}/${item.path}`} className='w-full'/>
                                </div >
                                <p className='font-medium text=[11pt]'>{item.descricao}</p>
                                <div className='absolute right-2 bottom-2 admin-button bg-red-500 hover:bg-red-400' onClick={()=>handleDelete(item.id)}>
                                    {removerLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : (<p className='text-[11pt] text-white m-0 p-0'>Remover</p>)}
                                    
                                </div>
                            </div>

                        ))}
                    </div>
                    ) : (
                        <p>Nenhuma imagem cadastrada</p>
                        ) 
                }
            </div>
            <hr/>
            <div className='flex justify-start flex-row'>
                <div className='flex w-full md:1/2'>
                {data? (<PhotoWidgetSingle setData={setData} data={data} semcapa={true} />) :null}
                </div>

            <div className='flex flex-col gap-1 flex-grow'>
                <div className='flex flex-row py-8 flex-wrap gap-1'>
                    <input
                      name="descricao"
                      onChange={handleChange}
                      id="descricao"
                      value={data.descricao}
                      placeholder='Descrição'
                      className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                    />
                    <input
                      name="titulo"
                      onChange={handleChange}
                      id="titulo"
                      value={data.titulo}
                      placeholder='Título'
                      className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                    />
                    <input
                      name="subtitulo"
                      onChange={handleChange}
                      id="subtitulo"
                      value={data.subtitulo}
                      placeholder='Subtítulo'
                      className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                    />
                </div>
                <button className={'bg-blue-600 text-slate-200 admin-button hover:bg-blue-400'} onClick={handleSubmit}>{submitLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Enviar')}</button>
                {scssMsg ? 
                <Toast msg={scssMsg} type='success'/>
                : null} 
            </div>  
            </div>

    
        </div>
        )}</>
)
}

function CarrocelLoja() {

    
  return (
    <div className='w-[100%] flex flex-col'>
        <CarrocelPannel/>
        <hr/>
    </div>
  )
}

export default CarrocelLoja