import React, { useRef, useState } from 'react';

function PhotoWidget(props) {
  const setData = props.setData;
  const data = props.data;
  const inputRef = useRef();


  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const imageFiles = Array.from(droppedFiles).filter((file) =>
      file.type.startsWith('image/')
    );
    setData({ imagens: [...data.imagens, ...imageFiles] });
  };

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    const imageFiles = Array.from(selectedFiles).filter((file) =>
      file.type.startsWith('image/')
    );

    setData({ imagens: [...data.imagens, ...imageFiles] });
    
  };

  const removeImage = (index) => {
    if (data.imagens) {
      const updatedimagens = [...data.imagens];
      updatedimagens.splice(index, 1); 
      setData({ imagens: updatedimagens });
    }
  };

  return (
    <div className='flex flex-col'>
      <div
        className='h-[150px] flex flex-col border-1 border-slate-400 border-dashed bg-slate-200 rounded-lg p-4 justify-center items-center cursor-pointer'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current.click()}
      >
        <p className='text-[10pt] text-slate-500'>Arraste e solte uma foto aqui ou clique</p>
        <p className='text-[10pt] text-slate-500'>para adicionar imagens</p>
        <input
          type='file'
          multiple
          onChange={handleFileSelect}
          accept='image/*' 
          hidden
          ref={inputRef}
        />
      </div>
      <div className='flex flex-row overflow-x-hidden overflow-y-hidden h-[100px] p-1 justify-center gap-2'>
      {data && data.imagens && data.imagens.map((file, index) => (
          <div className='relative h-[80px]' key={index}>
            <span
              className='absolute bg-slate-500 rounded-full pl-2 pr-2 -pt-1 text-white -right-2 -top-1 cursor-pointer opacity-80 flex justify-center items-center'
              onClick={() => removeImage(index)} 
            >
              x
            </span>
            <img src={URL.createObjectURL(file)} alt={`Image ${index}`} className='h-full rounded-md' />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoWidget;