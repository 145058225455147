import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
import { GetOrdem } from "Admin/state/api";

function VerPedido(props) {
  const id = props.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) {
      async function getPedido() {
        try {
          const response = await GetOrdem(id);
          setData(response);
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }
      getPedido();
    }
  }, [id]);

  const handleSubmit = (id) => {
    GenPut(`api/ordem/${id}`, { status: "enviando" }).catch((error) => {
      console.error(error);
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress color="success"/>
      </div>
    );
  }

  if (data) {
    return (
      <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg">
        <div className="w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Detalhes do Pedido</h2>
          <hr className="my-2" />
          <p className="text-gray-700">
            <strong>Nome do usuário:</strong> {data.user.name}
            <br />
            <strong>Data:</strong> {data.detalhesOrdem.created_at.slice(0, 10)}
            <br />
            <strong>ID:</strong> {data.detalhesOrdem.id}
            <br />
            <strong>Valor:</strong> {data.ordem.valor_total}
            <br />
            <strong>Status:</strong> {data.ordem.status}
          </p>
        <hr className="my-2" />
        <h2 className="text-xl font-semibold text-gray-800">Itens do Pedido</h2>
        <div>
          
          <div className="space-y-4">
            {data.itensOrdem.map((item) => (
              <div key={item.id} className="p-4 bg-white rounded-md shadow-sm">
                <p>
                  <strong>Produto:</strong> {item.nome_produto}
                </p>
                <p>
                  <strong>Quantidade:</strong> {item.quantidade}
                </p>
                <p>
                  <strong>Preço Unitário:</strong> {item.preco_unitario}
                </p>
                {item.estoque_unidade && (
                  <>
                    <p>
                      <strong>Tipo:</strong> {item.estoque_unidade.nome}
                    </p>
                    <p>
                      <strong>Produto:</strong> {item.estoque_unidade.produto_id}
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

      </div>
      </div>

    );
  }

  return null;
}

export default VerPedido;
