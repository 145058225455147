import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material';
import Img from '../../assets/BeniItalyBanner.png'
function Apresentacao() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="relative flex flex-col justify-center align-middle items-center overflow-hidden w-full" >
      <img src={Img} className='w-full h-[70%]'/>
    </div>

  )
}

export default Apresentacao