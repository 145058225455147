import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PoliticaAviso() {
    const nav = useNavigate()
    const [has_accepted, setAccepted] = useState(false);
    
    useEffect(() => {
        const accepted = localStorage.getItem('accept_cookies');
        if (accepted){
            setAccepted(true);
        }   
      },[has_accepted]);
    
    const handleAccept = () =>{
        localStorage.setItem('accept_cookies' , true); 
        setAccepted(true)
    }

    if (has_accepted){
        return null;

    } else{
        return (
            <div className='sticky bottom-0 w-full flex flex-row justify-between rounded shadow-md p-2 bg-slate-100 text-[8pt]'>
                <div className='flex flex-col'>
                    <h6 className='text-[10pt] font-semibold'>Utilizamos Cookies para melhorar a sua experiência!</h6>
                    <p className='ml-2'>
                        Ao continuar navegando, você aceita os nossos
                        <a className="text-blue-500 underline cursor-pointer" onClick={()=>nav('/termos')}> termos de uso </a>.
                    </p>
                </div>
                <div className='flex justify-center align-middle items-center'>
                    <button className='px-4 rounded-sm shadow-sm hover:bg-slate-700 bg-slate-800 text-white h-[40px] m-0 justify-center align-middle items-center text-[12pt]' onClick={handleAccept}>Continuar</button>
                </div>
        
            </div>
          )
    }

}

export default PoliticaAviso