import LoginForm from 'Loja/components/compLoja/LoginForm'
import React from 'react'
import { useParams } from 'react-router-dom';

function Login() {
  const { arg } = useParams();
  const argValue = arg ? arg.toString() : "";

  return (
    <div className='flex justify-center items-center w-full min-h-[70vh]'>
        <LoginForm arg={argValue}/>
    </div>
  )
}

export default Login