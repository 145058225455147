import React, {useState, useEffect} from 'react'
import{
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
  } from "@mui/material";
import { Card } from 'react-bootstrap';
import { GenGet, GetMessages, GenPost } from 'Loja/state/api';
import FormsModal from 'Loja/components/compLoja/FormsModal';
import NovoEnd from '../novoEnd/Index';
import AtualizarEnd from '../novoEnd/PutEnd';

const userId = localStorage.getItem('id');



function PerfilUsuario() {
    const [user, setUser] = useState(false);
    const [cliente, setCliente] = useState(false);
    const [avisos, setAvisos] = useState(false);
    const [enderecos, setEnd] = useState(false);
    const [pedidos, setPedidos] = useState(false);
    const [ completo, setCompleto] = useState(false);
    const [ message, setMessage] = useState('');
    const [ carregando, setCarregando] = useState(true);
    const [msg, setMsg] = useState(0);
    const [refresh, setRefresh] = useState(false);

    const [formData, setFormData] = useState({
      mensagem: "",
      status: "enviada",
      origem: userId,
      destino: "",
    });

    const handleSubmit = () => {
      GenPost("/novamsg", formData)
        .then(() => {
          async function fetchData() {
            const mensagem = await GetMessages(userId);
            setMsg(mensagem.mensagens);

      
          }
          fetchData();
        })
        .catch((error) => {

        });
    };

    const setMensagem = (e) => {
      setFormData({
        ...formData,
        mensagem: e.target.value,
        destino: 1
      });
    };
  

    const FORM_END = {
      'Novo Endereço': NovoEnd,
    };
    const VER_END = {
      'Ver Endereço': AtualizarEnd 
    };
       

  async function getUser() {  
    setCarregando(true);
    try {
      const response = await GenGet(`api/apiuser`);
      setUser(response.dados);
      setPedidos(response.ordems);
      setAvisos(response.avisos);
      setEnd(response.enderecos);
      
      
      check();
      setCarregando(false);
      setRefresh(false);

    } catch (err) {
      setCarregando(false);
      setRefresh(false);
    }
  }

  function check(){
    if(cliente.length > 0 && enderecos.length > 0){
        setCompleto(true);
        setMessage('');
         
    } else if (cliente.length === 0 && enderecos.length === 0){
        setMessage('Cadastre seus dados e um endereço.')  
   
    } else if (cliente.length > 0 && enderecos.length === 0){
      setMessage('Cadastre um endereço');

    }
  }

  useEffect(() => {
    getUser()
  }, []);

  useEffect(() => {
    async function fetchData() {
      const mensagem = await GetMessages(userId);
      setMsg(mensagem.mensagens);
    }
    fetchData();
    
  }, []);

  const handleCloseEnd = () => {
    setRefresh(true);
  }

  useEffect(() => {
    if (refresh){
      getUser();
    }
  }, [refresh]);

  useEffect(() => {
    console.log(enderecos)
  }, [enderecos]);
  

  function EnderecoItem({ endereco }) {
    const options = {
      'Ver Endereço': AtualizarEnd(endereco.id),
    };
  
    return (
      <Box key={endereco.id}>
        <Typography sx={{ marginTop: 1 }}>Endereço: {endereco.endereco}</Typography>
        <Typography sx={{ marginTop: 1 }}>Complemento: {endereco.complemento}</Typography>
        <Typography sx={{ marginTop: 1 }}>CEP: {endereco.cep}</Typography>
        <Typography sx={{ marginTop: 1 }}>Tipo: {endereco.tipo}</Typography>
        <Typography sx={{ marginTop: 1 }}>Cidade: {endereco.cidade}</Typography>
        <Typography sx={{ marginTop: 1 }}>Estado: {endereco.estado}</Typography>
        <FormsModal options={options} onClose={handleCloseEnd} id={endereco['id']} />
        <hr />
        <br />
      </Box>
    );
  }


  
  return (
    <Box className='container min-h-[90vh]' sx={{padding:5}}>
      <Typography variant='h5'>
        Meu Perfil
      </Typography>
      {carregando ?  (
          <div className="flex justify-center min-h-[90vh] items-center align-middle mt-10 mb-10">
            <CircularProgress color="success" />
          </div>
      ) :     
      <Grid container spacing={2}>
        <Grid item md={8} xs={12} >
          <Card className='shadow' >
            <Box sx={{padding:1}} height={'40vh'}>
              <Typography variant='h6'>Dados de Usuário</Typography>
              <hr/>        
              <Box display={'flex'} flexDirection='column' sx={{marginLeft:1}}>
                <Typography sx={{marginTop:1}}>Nome : {user.nome} {user.sobrenome}</Typography>
                <Typography sx={{marginTop:1}}>Nome de Usuário: {user.uname}</Typography>
                <Typography sx={{marginTop:1}}>Email: {user.email}</Typography>
                <Typography sx={{marginTop:1}}>{message}</Typography>          
              </Box>
            </Box>          
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card className='shadow'>
            <Box sx={{padding:1 ,maxHeight: '40vh', overflowY: 'auto'}} height={'40vh'}>
              <Typography variant='h6'>Endereços</Typography>
              <hr/>
             
              <Box display={'flex'} flexDirection='column' sx={{marginLeft:1}} height={'40vh'}>
              {enderecos && enderecos.length > 0  ? 
                      <>
                      {enderecos.map((endereco) => (
                        <EnderecoItem endereco={endereco} key={endereco.id} />
                      ))}
                    </>
              : <Typography sx={{marginTop:1}}>Nenhum endereço cadastrado. </Typography>}
                <FormsModal options={FORM_END} onClose={handleCloseEnd}/>  
              </Box>
            </Box>
          </Card>
        </Grid>
          <Grid item md={4} xs={12}>
            <Card className='shadow'>
              <Box sx={{padding:1 ,maxHeight: '40vh', overflowY: 'auto'}} height={'40vh'}>
                <Typography variant='h6'>Meus Pedidos</Typography>
                <hr/>
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:1}}>
                {pedidos && pedidos.length > 0  ? 
                <>
                
                {pedidos.map((item) =>{
                    const dateString = item.created_at;
                    const date = new Date(dateString);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                    const day = String(date.getDate()).padStart(2, "0");
                    const formattedDate = `${year}-${month}-${day}`;

                    return (
                      <Box key={item.id}>
                        <Typography sx={{marginTop:1}}>Numero do pedido : {item.id}</Typography>
                        <Typography sx={{marginTop:1}}>Data do Pedido: {formattedDate}</Typography>
                        <Typography sx={{marginTop:1}}>Valor Total: {item.valor_total}</Typography>
                        <Typography sx={{marginTop:1}}>Status: {item.status}</Typography>

                        <hr/><br/>
                      </Box>
                    )
                  })}
                </> : <Typography sx={{marginTop:1}}>Nenhum pedido. </Typography> }
                             
              </Box>
              </Box>
            </Card>
          </Grid>
        <Grid item md={4} xs={12}>
          <Card className='shadow'>
            <Box sx={{padding:1 ,maxHeight: '40vh', overflowY: 'auto'}} height={'40vh'}>
              <Typography variant='h6'>Meus Avisos</Typography>
              <hr/>
              <Box display={'flex'} flexDirection='column-reverse' sx={{marginLeft:1}}>
              {avisos && avisos.length > 0  ? <>

              {avisos.map((item) =>{
                return (
                  <Box key={item.id}>
                    <Typography sx={{marginTop:1}}>{item.aviso} {item.endereco1}</Typography>
                    <hr/><br/>
                  </Box>
                )
              })}

              </>
              : <Typography sx={{marginTop:1}}>Nenhum Aviso </Typography>}          
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card className='shadow'>
            <Box 
            display={'flex'}
            flexDirection='column'
            alignItems='flex-start'
            sx={{padding:0.3 ,maxHeight: '40vh', overflowY: 'auto'}} height={'40vh'}>
              <Typography variant='h6'>Caixa de entrada</Typography>
              <hr/>
              <Box display={'flex'} flexDirection='column' sx={{marginLeft:1, padding:1 ,maxHeight: '40vh', overflowY: 'auto'}}>
                <Box sx={{marginTop:0}} display={'flex'} flexDirection='column-reverse'>{msg && msg.length > 0 ? <>
                  {msg.map(({id, origem, mensagem, data}) => (
                  <Grid container spacing={1} display='flex' flexDirection={'row'} sx={{ backgroundColor:"#E6E6FA", borderRadius: 3, padding:1, marginTop:0.1 }} key={id}> 
                    <Grid item xs={12}>
                        <Typography variant='body1' sx={{fontSize:'10pt'}}>
                            {data}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0.2} display='flex' flexDirection={'row'} > 
                            <Grid item xs={3} >
                                <Typography variant='body1' sx={{fontSize:'10pt'}}>
                                    {origem} :
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant='body2' >
                                    {mensagem}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                  ))} 
                 
                 
                 </> :  <>Nenhuma mensagem.</>}</Box>             
              </Box>
              <Box                     
                  display={'flex'}
                  flexDirection='row'
                  alignItems='flex-end'
                  sx={{ marginLeft: 1}}
                  height='auto'
                  width={'95%'}
                >
                  <TextField
                    hiddenLabel
                    id="filled-hidden-label-small"
                    label="sua mensagem:"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <Button onClick={handleSubmit}>enviar</Button>
                </Box>
            </Box>
          </Card>
        </Grid>
    </Grid> }


    </Box>
  )
}

export default PerfilUsuario