import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import PublicosLeads from './PublicosLeads';
import { Dialog, DialogContent } from '@mui/material';
import NovoLead from './NovoLead';
import { CircularProgress } from '@mui/material';

function PainelLeads() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/leads/dashboard`);
            setData(response);
            setLoading(false);
            console.log(response)

        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    };
  
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const usuariosCols = ['ID', 'Nome','Email'];
  const avulsosCols = ['ID', 'Email'];
  return (
    <div className='flex flex-col w-full p-2 relative'>
    <h3 className='text-[14pt]'>Painel de Leads</h3>
    <p className='text-[9pt] -mt-2'>visualize e gerencie seus contatos aqui.</p>
    <hr/>
    <button className='absolute right-5 top-2 bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleOpen}>Novo Lead</button>
    <Dialog open={open} onClose={handleClose} style={{minWidth:'70vw'}}>
        <DialogContent>
          <NovoLead/>
        </DialogContent>
    </Dialog>
    {loading ? (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
    ) : (
      <div className='flex flex-col'>
        <GenericDataGrid rows={data.usuarios} columns={usuariosCols} per_page={15} title="usuários"/>
        <GenericDataGrid rows={data.avulsos} columns={avulsosCols} per_page={15} title="avúlsos"/>
      </div>
    )}
    <hr/>
    <PublicosLeads />
    </div>
  )
}

export default PainelLeads