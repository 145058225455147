import React, { useState } from 'react';

function CustomSelect(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const values = Object.entries(props.data);
  const [curVal, setCurVal] = useState()
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={`relative ${props.className}`}>
      <button
        className={`bg-slate-100 px-4 py-2 rounded border border-gray-300 focus:outline-none ${props.className}`}
        onClick={toggleDropdown}
      >
        {curVal? curVal : props.label}
      </button>
      {isOpen && (
        <div className='absolute top-full left-0 bg-white border border-gray-300 shadow-lg max-h-[300px] overflow-x-hidden overflow-y-auto z-1'>
          {props.search?( 
          <input
            type='text'
            id='pesquisar'
            name='pesquisar'
            placeholder='Pesquisar'
            className='px-4 py-2 border-b border-gray-300 focus:outline-none sticky-top'
            value={searchTerm}
            onChange={handleSearch}
          />):null}

          <ul>
            {values
              .filter(([id, value]) => value.toLowerCase().includes(searchTerm.toLowerCase()))
              .map(([id, value]) => (
                <li
                  key={id}
                  className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                  onClick={() => {
                    props.onChange(id, value);
                    setCurVal(value)
                    setIsOpen(false);
                  }}
                >
                  {value}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;