import React, {useContext} from 'react'
import { Typography, Box, Menu, MenuItem, Button} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {makeStyles} from '@material-ui/core/styles';
import LoginForm from './LoginForm';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from "react-router-dom";
import useLogout from 'hooks/useLogout';
import Img from '../../assets/logo-peppechild.png';


const useStyles = makeStyles({
    botaoHeader: {
      "&:hover": {
        color: "#feb236",
      }
    }
  });

function Header() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const logout = useLogout();
  
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openLogin, setOpenLogin] = React.useState(false); 
  const handleClickLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseNav = (link) => {
    setOpenLogin(false);
    navigate(link);
  };
  const handleLink = (link) => {
    setAnchorEl(null);
    navigate(link);
  };
  const classes = useStyles();
  return (
    <Box 
    sx={{
        display:"inline-flex", 
        width:"100%", 
        boxSizing:"border-box",
        color: "white",
        bgcolor: "#8c8c8c"}}
        >
        <Box className="container" 
        sx={{
            display:'flex',
            flexDirection: 'row',
            padding:2, 
            }}>
            
            <Box 
            sx={{
                width:"50%",
                display:{md:'flex', xs:'none'},
                flexDirection: 'row',
                gap:1}}>
          <Box display={{xs:'none', md:"block"}} >
            <img src={Img} width={120} height={50}/>
          </Box>

            </Box>
            {token ?             
              <Box 
            sx={{
                width:{lg:"50%", xs:"100%"},
                display:"flex",
                flexDirection: 'row-reverse', 
                gap:2,
                }}>
                <Button className={classes.botaoHeader}
                sx={{
                    display:"flex",
                    flexDirection: 'row', 
                    gap:1,
                    color:"white"
                    }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickMenu}>
                
                    <PersonIcon/>
                    <Typography>Minha Conta</Typography>            
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => {handleLink('/meupainel')}}><Typography>Meu Painel</Typography></MenuItem>
                    <MenuItem onClick={() => {logout()}}>Logout</MenuItem>
                  </Menu>
            </Box>
            :
            <Box 
            sx={{
                width:{lg:"50%", xs:"100%"},
                display:"flex",
                flexDirection: 'row-reverse', 
                gap:2,
                }}>
                <Button className={classes.botaoHeader}
                sx={{
                    display:"flex",
                    flexDirection: 'row', 
                    gap:1,
                    color:"white"
                    }} 
                onClick={handleClickLogin}>
                    <PersonIcon/>
                    <Typography>Fazer Login</Typography>            
                </Button>
          
            </Box>
            }

        </Box>
        <Dialog open={openLogin} onClose={handleCloseLogin}>
        <DialogContent>
          <LoginForm onClose={handleCloseNav}/>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default Header