import { CircularProgress } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { GenGet } from 'Admin/state/api';
import React, { useEffect, useState } from 'react';

function MarketingProduto({ id }) {
    const [data, setData] = useState({ acessos: [] });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            setIsLoading(true); // Set loading state to true when fetching new data
            try {
                const response = await GenGet(`/marketing/produto/${id}`);
                console.log(response);
                setData(response);
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false); // Ensure loading state is false after fetching data
            }
        }
        if (id) {
            getData();
        }
    }, [id]);

    const columns = ['ID', 'Usuário','Produto', 'Acessos'];

    return (
        <div className='flex flex-col w-full mt-2 p-2 text-[10pt]'>
            <h3 className='text-[14pt]'>Painel de métricas do Produto</h3>
            <p className='text-[9pt] -mt-2'>Visualize as métricas de seu produto</p>
            {!isLoading ? (
                <div className='flex flex-col w-full gap-2'>
                    <div className='flex flex-col p-2 text-[18pt] font-semibold text-gray-800'>
                        <p className='text-[12pt] p-0 m-0'>Total de acessos</p>
                        <p className='p-0 m-0'>{data.total_acessos}</p>
                    </div>
                    <GenericDataGrid rows={data.acessos} columns={columns} per_page={10} title="Histórico do Produto" />
                </div>
            ) : (
                <div className='flex justify-center items-center h-full w-full'>
                    <CircularProgress color="success" />
                </div>
            )}
        </div>
    );
}

export default MarketingProduto;