import React, { useEffect, useState } from 'react';
import ParametrosEmail from './ParametrosEmail'
import { GenGet, GenPost } from 'Admin/state/api';
import { CircularProgress } from '@mui/material';

function ParametrosGerais() {
  const [refresh, setRefresh] = useState(false);
  const [freteMsg, setFreteMsg] = useState(null);
  const [geralMsg, setGeralMsg] = useState(null);
  const [pagamentoMsg, setPagamentoMsg] = useState(null);
  const [pagamentoUpdateLoading, setPagamentoUpdateLoading] = useState(false);
  const [freteUpdateLoading, setFreteUpdateLoading] = useState(false);
  const [geralUpdateLoading, setGeralUpdateLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [freteFormData, setFreteFormData] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: 0,
    frete_isento_tipo: 'regiao',
  });

  const [paramsFormData, setParamsFormData] = useState({
    facebook: '',
    instagram: '',
    whatsapp: '',
    exibir_estoque:0
  });

  const [pagFormData, setPagFormData] = useState({
    integracao_pagamento: 'mercadopago',
    cupom_ativo: 0,
  });

  const [pagvalidationErrors, setPagValidationErrors] = useState({
    integracao_pagamento: 'melhorenvio',
    cupom_ativo: 0,
  });

  const [validationErrors, setValidationErrors] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: 0,
    frete_isento_tipo: 'regiao',
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
    setFreteFormData({
      ...freteFormData,
      [e.target.name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: value ? '' : 'Campo obrigatório.',
    });
  };

  const handleParamsChange = (e) => {
    const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
    setParamsFormData({
      ...paramsFormData,
      [e.target.name]: value,
    });
  };

  const handlePagChange = (e) => {
    const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
    setPagFormData({
      ...pagFormData,
      [e.target.name]: value,
    });
    setPagValidationErrors({
      ...pagvalidationErrors,
      [e.target.name]: value ? '' : 'Campo obrigatório.',
    });
  };

  const handlePost = async () => {
    try {

      setFreteUpdateLoading(true)
      
      const response = await GenPost('/params/frete', freteFormData);
      setRefresh(true); // Trigger refresh after successful post
      setFreteMsg(response.message);
      setFreteUpdateLoading(false)
    } catch (err) {
      console.error('Error posting data:', err);
      setFreteMsg(err.message);
      setFreteUpdateLoading(false)
    
    } finally {
      setTimeout(() => {
        setFreteMsg(null);
      }, 3000);
    }
  };

  const handlePagPost = async () => {
    try {
      setPagamentoUpdateLoading(true)
      const response = await GenPost('/params/pagamento', pagFormData);
      setRefresh(true); // Trigger refresh after successful post
      setPagamentoMsg(response.message);
      setPagamentoUpdateLoading(false)

    } catch (err) {
      console.error('Error posting data:', err);
      setPagamentoMsg(err.message);
      setPagamentoUpdateLoading(false)

    } finally {
      setTimeout(() => {
        setPagamentoMsg(null);
      }, 3000);
    }
  };

  const handleParamPost = async () => {
    try {
      setGeralUpdateLoading(true)
      const response = await GenPost('/params/geral', paramsFormData);
      setRefresh(true); // Trigger refresh after successful post
      setGeralMsg(response.message);
      setGeralUpdateLoading(false)

    } catch (err) {
      console.error('Error posting data:', err);
      setGeralMsg(err.message);
      setGeralUpdateLoading(false)

    } finally {
      setTimeout(() => {
        setGeralMsg(null);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //
        const response = await GenGet('/params/fretes');
        const responseGeral = await GenGet('/params/geral');

        setParamsFormData({
          facebook: responseGeral.facebook,
          instagram: responseGeral.facebook,
          whatsapp: responseGeral.facebook,
          exibir_estoque: responseGeral.exibir_estoque
        });

        setPagFormData({
          integracao_pagamento: responseGeral.integracao_pagamento,
          cupom_ativo: responseGeral.cupom_ativo,
        })

        setFreteFormData({
          integracao_frete: response.integracao_frete,
          frete_isento: response.frete_isento,
          frete_isento_tipo: response.frete_isento_tipo,
        })
  
        setLoading(false);
      } catch (err) {
        console.error('Error fetching fretes:', err);
      }
    };
  
    fetchData();
  }, [refresh]);
  
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);
  if (loading){
    return (
      <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
        <CircularProgress color="success"/>
      </div>)
    } else {

    return (
      <div className='flex flex-col w-full min-h-[100%] p-2 text-[10pt]'>
        <h3 className='text-[14pt]'>Parâmetros Gerais</h3>
        <p className='text-[9pt] -mt-2'>configurações gerais de sua loja</p>

        <h3 className='input-classes text-[12pt]'>Configurações de pagamento</h3>
        <div className='w-full p-2'>
            <div className='flex flex-row text-left gap-2 justify-between'>
              <div className='flex flex-row justify-start gap-2 mr-4'>
                <label className='font-semibold'>Cupom de desconto ativo:</label>
                <input
                  className='bg-slate-300 rounded h-[30px] flex p-1.5'
                  id='cupom_ativo'
                  name='cupom_ativo'
                  type='checkbox'
                  checked={pagFormData.cupom_ativo === 1  || pagFormData.cupom_ativo === true }
                  onChange={handlePagChange}
                />
              </div>
              <div className='flex flex-col justify-between'>
                <label>Integração Pagamento:</label>
                <select
                  className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                  aria-label='integracao_pagamento'
                  onChange={handlePagChange}
                  id='integracao_pagamento'
                  name='integracao_pagamento'
                  value={pagFormData.integracao_pagamento}
                >
                  <option value='mercadopago'>Mercado Pago</option>
                </select>
              </div>
              <div className='flex gap-2 pt-4'>
                <button
                  className='bg-blue-500 hover:bg-blue-400 admin-button'
                  onClick={handlePagPost}
                >
                  {pagamentoUpdateLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Atualizar')}
                </button>
              </div>
            </div>
        </div>
        {pagamentoMsg && (
                <div className='flex justify-end w-full text-right text-gray-800 text-[10pt] p-2'>
                  <p className='text-right'>{pagamentoMsg}</p>
                </div>
              )}
        <hr/>
        <h3 className='input-classes text-[12pt]'>Configurações de frete</h3>
        <div className='w-full p-2'>
            <div className='flex flex-row text-left justify-between gap-2'>
              <div className='flex flex-row justify-start gap-2 mr-4'>
                <label className='font-semibold'>Frete Isento:</label>
                <input
                  className='bg-slate-300 rounded h-[30px] flex p-1.5'
                  id='frete_isento'
                  name='frete_isento'
                  type='checkbox'
                  checked={freteFormData.frete_isento === 1 || freteFormData.frete_isento === true }
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col justify-between'>
                <label>Tipo Isenção:</label>
                <select
                  className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                  aria-label='frete_isento_tipo'
                  onChange={handleChange}
                  id='frete_isento_tipo'
                  name='frete_isento_tipo'
                  value={freteFormData.frete_isento_tipo}
                >
                  <option value='regiao'>Região</option>
                  <option value='regiao_retirada'>Região + (retirada)</option>
                  <option value='valor'>Acima de um valor</option>
                  <option value='valor_retirada'>Acima de um valor + (retirada)</option>
                  <option value='regiao_e_valor'>Região e Valor</option>
                  <option value='regiao_ou_valor'>Região ou Valor</option>
                  <option value='regiao_e_valor_retirada'>Região e Valor + (retirada)</option>
                  <option value='regiao_ou_valor_retirada'>Região ou Valor + (retirada)</option>
                </select>
              </div>
              <div className='flex flex-col justify-between'>
                <label>Integração:</label>
                <select
                  className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                  aria-label='integracao_frete'
                  onChange={handleChange}
                  id='integracao_frete'
                  name='integracao_frete'
                  value={freteFormData.integracao_frete}
                >
                  <option value='melhorenvio'>Melhor Envio</option>
                </select>
              </div>
              <div className='flex gap-2 pt-4'>
                <button
                  className='bg-blue-500 hover:bg-blue-400 admin-button'
                  onClick={handlePost}
                >
                  {freteUpdateLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Atualizar')}
                </button>
              </div>
            </div>  
            {freteMsg && (
                <div className='flex justify-end w-full text-right text-gray-800 text-[10pt] p-2'>
                  <p className='text-right'>{freteMsg}</p>
                </div>
              )}      
        </div>
        <hr/>
        <h3 className='input-classes text-[12pt]'>Configurações da loja</h3>
        <div className='w-full p-2'>
            <div className='flex flex-row text-left justify-between gap-2'>
              <div className='flex flex-row justify-start gap-2 mr-4'>
                <label className='font-semibold'>Exibir estoque no produto:</label>
                <input
                  className='bg-slate-300 rounded h-[30px] flex p-1.5'
                  id='exibir_estoque'
                  name='exibir_estoque'
                  type='checkbox'
                  checked={paramsFormData.exibir_estoque === 1 || paramsFormData.exibir_estoque === true}
                  onChange={handleParamsChange}
                />
              </div>
            </div>          
        </div>
        <h3 className='input-classes text-[12pt]'>Redes sociais</h3>
            <div className='flex flex-row text-left justify-between gap-2'>
              <div className='flex flex-col justify-start gap-2 mr-4'>
                <label className='font-semibold'>Facebook</label>
                <input
                  className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                  id='facebook'
                  name='facebook'
                  value={paramsFormData.facebook}
                  onChange={handleParamsChange}
                />
              </div>
              <div className='flex flex-col justify-start gap-2 mr-4'>
                <label className='font-semibold'>Instagram</label>
                <input
                  className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                  id='instagram'
                  name='instagram'
                  value={paramsFormData.instagram}
                  onChange={handleParamsChange}
                />
              </div>
              <div className='flex flex-col justify-start gap-2 mr-4'>
                <label className='font-semibold'>WhatsApp</label>
                <input
                  className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                  id='whatsapp'
                  name='whatsapp'
                  value={paramsFormData.whatsapp}
                  onChange={handleParamsChange}
                />
              </div>
            
            </div>
            <div className='flex w-full flex-row-reverse pt-4'>
                <button
                  className='bg-blue-500 hover:bg-blue-400 admin-button'
                  onClick={handleParamPost}
                >
                  {geralUpdateLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Atualizar')}
                </button>
              </div>
            {geralMsg && (
              <div className='flex justify-end w-full text-right text-gray-800 text-[10pt] p-2'>
                <p className='text-right'>{geralMsg}</p>
              </div>
            )}
        <hr/>
        <ParametrosEmail />
      </div>
    )
  }
}

export default ParametrosGerais