import React, {useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'Loja/state/axios';

import { GenPost, GenPostLogin } from 'Loja/state/api';
import { UserIsAuth } from 'context/Auth';
import { CartContext } from 'Ecommerce';
import SubmitButton from 'Admin/components/SubmitButton';
import Toast from 'Admin/components/Toast';

const LOGIN_URL = 'api/login';

const Registrar = ({handleFormTypes, arg}) =>{
  const { setLogging } = useContext(CartContext);
  const [errMsg, setErrMsg] = useState(null);
  const [scssMsg, setScssMsg] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); 
  const [postIsLoading, setPostIsLoading] = useState(false); 
  const [registrarForm, setRegistrarForm] = useState({
    nome: "",
    sobrenome:"",
    email: "",
    password: "",
    password_confirmation:"",
    telefone:"",
    cpf:"",
  });

  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          const timer = setTimeout(async () => {
            const isAuth = await UserIsAuth();
            if (isAuth) {
              navigate(`/novaordem`);
            } else {
              localStorage.removeItem('token');
            }
          }, 2000);
  
          return () => clearTimeout(timer);
  
        } catch (error) {
          localStorage.removeItem('token');
        }
      }
    };

    checkAuth();
  
  }, [token]);


  const handleRegistrarForm = (e) =>{
    setRegistrarForm({
      ...registrarForm,
      [e.target.name]:e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if(registrarForm.password !== registrarForm.password_confirmation){
      setErrMsg("As senhas não conferem.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
      return;
    }

    try{
      setPostIsLoading(true);
      const response = await GenPost('/api/register', registrarForm);
      setLogging(true); 
      localStorage.setItem('token' , response?.token); 
      
      setScssMsg('Conta registrada com Sucesso, aguarde');
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);

      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000); 
      setPostIsLoading(false);
      return () => clearTimeout(timer);

    } catch (error) {
      setPostIsLoading(false);
      setErrMsg('Ocorreu algum erro. Tente novamente.');

      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
      
    }       
  }

  return(
    <div className='flex flex-col justify-center align-middle items-center min-h-[70vh]'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Crie uma conta 
        </h3>
        {arg === 'register' ? (<p className='font-semibold -mt-2 text-slate-800 '>para finalizar sua compra!</p>) : null }

        <div className='flex flex-col gap-2 mt-1 w-[100%]'>
          <div className='flex flex-row gap-2 justify-between  w-[100%]'>
            <div className='flex flex-col justify-between'>
            <label className=''>nome:</label>
            <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
              id="nome"
              name="nome"
              value={registrarForm.nome} 
              onChange={handleRegistrarForm}  
              />
            </div>
            <div className='flex flex-col justify-between'>
            <label className=''>sobrenome:</label>
            <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
              id="sobrenome"
              name="sobrenome"
              value={registrarForm.sobrenome} 
              onChange={handleRegistrarForm}  
              />
            </div>
          </div>
          <div className='flex flex-row gap-2 justify-between  w-[100%]'>
            <div className='flex flex-col justify-between'>
            <label className=''>cpf:</label>
            <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
              id="cpf"
              name="cpf"
              value={registrarForm.cpf} 
              onChange={handleRegistrarForm}  
              />
            </div>
            <div className='flex flex-col justify-between'>
            <label className=''>telefone:</label>
            <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
              id="telefone"
              name="telefone"
              value={registrarForm.telefone} 
              onChange={handleRegistrarForm}  
              />
            </div>   
          </div>

        <div className='flex flex-col justify-between  w-[100%]'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={registrarForm.email} 
            onChange={handleRegistrarForm}  
            />
        </div>
        <div className='flex flex-col justify-between w-[100%]'>
          <label className=''>senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="password"
            type="password"
            name="password"
            value={registrarForm.password} 
            onChange={handleRegistrarForm}  
            />
        </div>
        <div className='flex flex-col justify-between  w-[100%]'>
          <label className=''>confirmar senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            value={registrarForm.password_confirmation} 
            onChange={handleRegistrarForm}  
            />
        </div>  

          <div className='w-full flex flex-row justify-end my-1'>
          <SubmitButton isLoading={postIsLoading} onClick={handleSubmit}>
            Registrar
          </SubmitButton>
          </div>
          <div className='w-full flex flex-row justify-center my-1 h-[50px]'>
          {errMsg ? <Toast msg={errMsg} type='error'/>
          : null}

          {scssMsg ? <Toast msg={scssMsg} type='success'/>
          : null}
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Já tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Login')}> Entrar</span></p>
          </div>
        </div>
      </div>
  )
}

const Login = ({handleFormTypes}) =>{
  const cartitens = localStorage.getItem('cart');
  const { setLogging } = useContext(CartContext);
  const [postIsLoading, setPostIsLoading] = useState(false); 
  const[errMsg, setErrMsg] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); 
  const [loginForm, setLoginForm] = useState({
    email:'',
    password:''
  });

  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          const timer = setTimeout(async () => {
            const isAuth = await UserIsAuth();
            
            if (isAuth) {
              navigate(`/`);
            } else {
              localStorage.removeItem('token');
            }
          }, 2000);
  
          return () => clearTimeout(timer);
  
        } catch (error) {
          localStorage.removeItem('token');
        }
      }
    };

    checkAuth();
  
  }, [token]);

  const handleLoginForm = (e) =>{
    setLoginForm({
      ...loginForm,
      [e.target.name]:e.target.value
    })
  }


const handleSubmit = async (e) => {
    e.preventDefault();
  
    try{
      setPostIsLoading(true);
      const response = await axios({
        url: LOGIN_URL,
        method: 'POST',
        data: JSON.stringify(loginForm),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',},
        withCredentials:true,     
      });
      setLogging(true); 

      localStorage.setItem('token' , response?.data?.token); 

        if (JSON.parse(cartitens) && JSON.parse(cartitens).length > 0){
          console.log(JSON.parse(cartitens))
          try { 
            await GenPost(`api/delcart/${response?.data?.user.id}`);
            await Promise.all(JSON.parse(cartitens).map((item) => {
              const modifiedItem = { 
                'produto_id':item.produto_id,
                'quantidade':parseInt(item.quantidade),
                'estoque_unidade_id':item.estoque_unidade_id ? parseInt(item.estoque_unidade_id) : null,
                'user_id':response?.data?.user.id 
              }; 
                return GenPostLogin('/api/ucartitens', modifiedItem, response?.data?.token);
            }));   
          setLogging(false);
          localStorage.removeItem('cart');
          } catch (error) {
            // Trata erros
          }       
        }

        setScssMsg('Login realizado com sucesso');
        setTimeout(() => {
          setScssMsg(null);
        }, 2000);

        const timer = setTimeout(() => {
          window.location.reload();
        }, 1000); 
        setPostIsLoading(false);
        return () => clearTimeout(timer);


    }catch (err) {
      setPostIsLoading(false);
      setErrMsg('Falha em Login.')
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  };
  
  return(
    <div className='flex flex-col justify-center align-middle items-center min-h-[500px]'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Entrar
        </h3>
        <div className='flex flex-col gap-2 mt-1  w-full'>
        <div className='flex flex-col justify-between'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={loginForm.email} 
            onChange={handleLoginForm}  
            />
          </div>
        <div className='flex flex-col justify-between  w-full'>
          <label className=''>senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="password"
            name="password"
            type="password"
            value={loginForm.password} 
            onChange={handleLoginForm}  
            />
        </div>
          <div className='w-full flex flex-row justify-end '>
            <SubmitButton isLoading={postIsLoading} onClick={handleSubmit}>
            Entrar
            </SubmitButton>
          </div>
          <div className='w-full flex flex-row justify-center my-2 h-[50px]'>
            {errMsg ? 
              <Toast msg={errMsg} type='error'/>
            : null} 
            {scssMsg ? 
              <Toast msg={scssMsg} type='success'/>
            : null} 
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Recuperar')}>esqueceu a senha?</p>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Não tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Registrar')}>Registrar</span></p>
          </div>
        </div>
      </div>
  )
}

const RecuperarSenha = ({handleFormTypes}) =>{
  const[errMsg, setErrMsg] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null);
  const [postIsLoading, setPostIsLoading] = useState(false);  
  const [recuperarForm, setRecuperarForm] = useState({
    email: "",
  });
  const handleRecuperarForm = (e) =>{
    setRecuperarForm({
      ...recuperarForm,
      [e.target.name]:e.target.value
    })
  }

  async function handleSubmit() { 
    try {
      setPostIsLoading(true);
      const response = await GenPost(`api/recuperar-senha`, recuperarForm);
      console.log(response);
      setScssMsg(response.message);
      setPostIsLoading(false);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
    } catch (err) {
      console.log(err);
        setErrMsg('Ops, Algo deu errado! Verifique o email que foi submetido.');
        setPostIsLoading(false);
        setTimeout(() => {
          setErrMsg(null);
        }, 3000);
        
    }

  }

  return(
    <div className='flex flex-col'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Esqueceu a senha?
        </h3>
        <p className='font-semibold -mt-2 ml-2 text-[11pt]'>Vamos enviar um email de recuperação!</p>

        <div className='flex flex-col gap-2 mt-1'>
        <div className='flex flex-col justify-between'>
          <label className=''>Digite seu email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={recuperarForm.email} 
            onChange={handleRecuperarForm}  
            />
          </div>

          <div className='w-full flex flex-row justify-end mt-2 mb-2'>
            <SubmitButton isLoading={postIsLoading} onClick={handleSubmit}>
              Recuperar
            </SubmitButton>
          </div>
          <div className='w-full flex flex-row justify-center my-2 h-[50px]'>
            {errMsg ? <Toast msg={errMsg} type='error'/>
            : null}

            {scssMsg ? <Toast msg={scssMsg} type='success'/>
            : null}
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Login')}>voltar para login</p>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Não tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Registrar')}> Registrar</span></p>
          </div>
        </div>
      </div>
  )
}

export default function LoginForm({arg}) {
  const [formType, setFormType] = useState('Login')
  const token = localStorage.getItem('token');
  const [verifyAuth, setVerifyAuth] = useState(true);
  const handleFormTypes = (type) =>{
    setFormType(type)
  }
  
  useEffect(() => {

    if(arg && arg === 'register'){
      setFormType('Registrar')
    }

  },[arg]);

return (
  <div className='p-2'>
    {formType === 'Login' ? (
      <Login handleFormTypes={handleFormTypes}/>
    ) : formType === 'Registrar' ? (
      <Registrar handleFormTypes={handleFormTypes} arg={arg}/>
    ) : (
      <RecuperarSenha handleFormTypes={handleFormTypes}/>
    )}
    {token && verifyAuth &&  (<Toast type="info" msg={'Verificando sua sessão aguarde.'} />)}
  </div>
);
}
