import LoginFormSistema from 'Admin/components/LoginFormSistema';
import React from 'react'
import { useParams } from 'react-router-dom';

function LoginAdmin() {
  const { arg } = useParams();
  const argValue = arg ? arg.toString() : "";

  return (
    <div className='flex justify-center items-center w-full min-h-[70vh]'>
        <LoginFormSistema arg={argValue}/>
    </div>
  )
}

export default LoginAdmin