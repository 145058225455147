import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import useLogout from "hooks/useLogout";
import {
    NotificationsNone as NotificationIcon,
    AttachMoneyOutlined as MoneyIcon,
    Apps as AppIcon,
    LocalShipping as LocalShippingIcon,
    Menu as MenuIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem 
} from "@material-ui/core";

const SalesPannel = () => {
    const navigate = useNavigate();
    const isSmScreen = useMediaQuery('(max-width:900px)');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState('');
    const logout = useLogout()
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {

      }, [title]);

  return (
    <div className={'flex flex-row w-full h-[100vh] bg-[#1f1f1f] text-white'}>
        <div className={`fixed inset-0 z-50 ${isDrawerOpen ? 'block' : 'hidden'}`} onClick={() => setIsDrawerOpen(false)}></div>
        <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-[#1f1f1f] overflow-y-auto transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
            <div className="h-screen overflow-y-auto flex flex-col min-w-[200px] bg-[#1f1f1f] no-scrollbar">
                <p className="text-white m-2 text-lg">Painel Vendedor</p>
                <ul className="space-y-2">
                    {/* Painel */}
                    <li className="flex w-full flex-col">
                        <button 
                            onClick={() => { navigate('/sistema'); setTitle('VENDEDOR'); }}
                            className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                        >
                            <AppIcon />
                            Sistema
                        </button>
                    </li>
                    <li className="flex w-full flex-col">
                        <button 
                            onClick={() => { navigate('/vendas'); setTitle('VENDEDOR'); }}
                            className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                        >
                            <MoneyIcon />
                            Realizar Venda
                        </button>
                    </li>


                    {/* Avisos */}
                    <li className="flex w-full flex-col">
                        <button 
                            onClick={() => { navigate('/vendas/avisos'); setTitle('Avisos'); }}
                            className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                        >
                            <NotificationIcon />
                            Avisos
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div className="h-screen overflow-y-auto flex flex-col min-w-[200px] md:flex bg-[#1f1f1f] no-scrollbar">
            <p className="text-white m-2 text-lg">Painel Vendedor</p>
            <ul className="space-y-2">
                {/* Painel */}
                <li className="flex w-full flex-col">
                        <button 
                            onClick={() => { navigate('/sistema'); setTitle('VENDEDOR'); }}
                            className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                        >
                            <AppIcon />
                            Sistema
                        </button>
                    </li>
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/vendas'); setTitle('VENDEDOR'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <MoneyIcon />
                        Realizar Venda
                    </button>
                </li>


                {/* Avisos */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/vendas/avisos'); setTitle('Avisos'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <NotificationIcon />
                        Avisos
                    </button>
                </li>
            </ul>
        </div>

        <div className="flex flex-col h-[100vh] flex-grow text-white">
            <div className="h-[8vh] flex flex-row p-1">
                <div className="flex w-full justify-between mt-[1px]">
                    <div className="flex">
                        {isSmScreen && (
                            <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                                {isDrawerOpen ? <CloseIcon style={{color: 'white'}}/> : <MenuIcon style={{color: 'white'}}/>}
                            </IconButton>
                        )}
                        <p className="font-[20px] ml-1 mt-2"><b>{title}</b></p>
                    </div>
                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Tooltip title="Configurações de conta">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 1,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Avatar /> Perfil
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Sair
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                </div>
            </div>

            <div className="flex min-h-[92vh] overflow-y-scroll flex-col w-[100%] bg-white text-gray-900 p-2 rounded-lg">
                <Outlet />
                <div className="min-h-[60vh]"></div>
            </div>
        </div>
    </div> 
  );
};

export default SalesPannel;