import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from "Admin/state/api";
import CustomSelect from 'Admin/components/CustomSelect';

function NovoCupom({ handleRefresh }) {
  const [data, setData] = useState({});
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
    user_id: 0,
    codigo: "",
    tipo: "campanha",
    validade: "",
    tipo_desconto: "porcentagem",
    valor_desconto: "",
    ativo: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    nome: "",
    descricao: "",
    user_id: "",
    codigo: "",
    tipo: "",
    validade: "",
    tipo_desconto: "",
    valor_desconto: "",
    ativo: "",
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: value ? "" : "Campo obrigatório.",
    });
  };

  const handleUser = (id) => {
    const user = userData.find(item => item.id === Number(id));

    if (user) {
      setFormData({
        ...formData,
        user_id: user.id,
      });

    } else {
      setFormData({
        ...formData,
        user_id: null,
      });
    }
  };

  const handlePost = () => {
    async function postData() {
      try {
        console.log(formData);
        const response = await GenPost("/cupoms/store", formData);
        setData(response);
        handleRefresh(true);
        console.log(response);
      } catch (err) {
        console.log(formData);
      }
    }
    postData();
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/email/formdata`);
            setUserData(response.users)         
            const formattedUserList = response.users.reduce((acc, user) => {
              acc[user.id] = user.nome;
              return acc;
            }, {});
            setUserList(
              {...formattedUserList,
              0:'não destinado'});
            setIsLoading(false);
            
        } catch (err) {
            setIsLoading(false)
        }
    };
  
    fetchData();
  }, []);

  return (
    <div className="rounded-md flex flex-col w-full bg-slate-50 p-4">
      <div className="flex flex-col text-left overflow-hidden">
        <h2 className="input-classes text-lg">Novo Cupom</h2>
        <div className="flex flex-col justify-between">
          <label className="">Nome:</label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Descrição:</label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="descricao"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Código:</label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="codigo"
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Tipo:</label>
          <select
            className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
            aria-label="tipo"
            onChange={handleChange}
            id="tipo"
            name="tipo"
            value={formData.tipo}
          >
            <option value={"campanha"}>Campanha</option>
            <option value={"parceria"}>Parceria</option>
            <option value={"afiliacao"}>Afiliaçao</option>
          </select>
        </div>

        <div className="flex flex-col justify-between">
          <label className="">Validade:</label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="validade"
            name="validade"
            type="date"
            value={formData.validade}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Tipo do Desconto:</label>
          <select
            className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
            aria-label="tipo_desconto"
            onChange={handleChange}
            id="tipo_desconto"
            name="tipo_desconto"
            value={formData.tipo_desconto}
            error={!!validationErrors.tipo_desconto}
            helperText={validationErrors.tipo_desconto}
          >
            <option value={"valor"}>Valor</option>
            <option value={"porcentagem"}>Porcentagem</option>
          </select>
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Valor: </label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="valor_desconto"
            name="valor_desconto"
            value={formData.valor_desconto}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="">Destinado ao usuário: </label>
          {userList && (<CustomSelect className={'min-w-[200px]'} data={userList} label={'Selecione'} onChange={handleUser} search={true}/>)}
        </div>
        <div className="flex flex-row justify-start gap-2">
          <label className="">Ativo: </label>
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="ativo"
            name="ativo"
            type="checkbox"
            value={formData.ativo}
            onChange={handleChange}
          />
        </div>

        <div className="flex w-full justify-end gap-2 mt-4 p-4">
          <button
            className="bg-blue-400 hover:bg-blue-500 admin-button"
            onClick={handlePost}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  );
}

export default NovoCupom;
