import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import Toast from 'Admin/components/Toast';
const appUrl = process.env.REACT_APP_BASE_URL;

function MovimentoEstoqueComposto({ data }) {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');
  const [movMsg, setMovMsg] = useState('');
  const [unidades, setUnidades] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [classeFormData, setClasseFormData] = useState({
    quantidade: 0,
    nome: '',
    descricao: '',
    estoque_id: data.estoque.id,
    produto_id: data.id,
  });

  useEffect(() => {
    setClasseFormData({
      quantidade: 0,
      nome: '',
      descricao: '',
      estoque_id: data.estoque.id,
      produto_id: data.id,
    })
  }, [data]);

  const [classeItemFormData, setClasseItemFormData] = useState({
    estoque_tipos_id: '',
    estoque_tipo_itens_id: '',
    estoque_tipo_itens_valor: '',
  });

  const [movFormData, setMovFormData] = useState({
    tipo:'',
    quantidade:0,
    estoque_unidade_id:''
  });

  const [classeItensFormData, setClasseItensFormData] = useState([]);
  const [tiposList, setTiposList] = useState({});
  
  const [tiposItemList, setTiposItemList] = useState(null);
  const [tipos, setTipos] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const handleTipo = (id, value) => {
    setClasseItemFormData({
      ...classeItemFormData,
      estoque_tipos_id:id,
      estoque_tipo_itens_valor:value
    })
    const tipoEncontrado = tipos.find(item => item.nome === value);
    setTiposItemList({});
    tipoEncontrado.itens.map((item) => {
      setTiposItemList((prevTipos) => ({
        ...prevTipos,
        [item.id]: item.valor
      }));
    });
  };

  const handleTipoItemId = (id, value) => {
    setClasseItemFormData({
      ...classeItemFormData,
      estoque_tipo_itens_id: id,
      estoque_tipo_itens_valor: value,
    });
  };

  const handleField = (e) => {
    setClasseFormData({
      ...classeFormData,
      [e.target.name]: e.target.value,
    });
  };

  const addClasseItem = () => {
    const exists = classeItensFormData.find(item => item.estoque_tipos_id === classeItemFormData.estoque_tipos_id);
    if (exists) {
      alert("Já existe um item deste tipo.");
    } else {
      setClasseItensFormData([...classeItensFormData, classeItemFormData]);
      setClasseItemFormData({
        estoque_tipos_id: '',
        estoque_tipo_itens_id: '',
        estoque_tipo_itens_valor: '',
      });
      setShowForm(false);
    }
  };

  const removeClasseItem = (id) => {
    setClasseItensFormData(classeItensFormData.filter(item => item.estoque_tipos_id !== id));
  };

  const handlePost = () =>{
    const data = {
      estoque_unidade: classeFormData,
      itens:classeItensFormData
    }
    async function PostForm() {
    try {
      const res = await GenPost('/cadastrar_unidade', data);
      setMsg(res.message);
      console.log(res)
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      setRefresh(true);
      setClasseItemFormData({
        estoque_tipos_id: '',
        estoque_tipo_itens_id: '',
        estoque_tipo_itens_valor: '',
      });
      setClasseFormData({
        ...classeFormData,
        quantidade: 0,
        nome: '',
        descricao: '',
      });

      setClasseItensFormData([])
    } catch (err) {
      setMsg(err.message);
      console.log(err)
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      setRefresh(true);
    }
  }
  PostForm()
    console.log(data)
  }

  const handleSubmitMov = () =>{
    async function PostMov() {
      try {
        const response = await GenPost('/mov-estoque-composto', movFormData);
        console.log(movFormData)
        setMovMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setRefresh(true);

      } catch (err) {
      setMsg(err.message);
      setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostMov();
  }

  const handleCancelMov = () =>{
    setMovFormData({
      tipo:'',
      quantidade:0,
      estoque_unidade_id:''
    })
    setUnidadePointer(null);
    setUnidadePointerUpdate(null);
  }

  useEffect(() => {
    async function getTipos() {
      try {
        const response = await GenGet("/retornar_tipo_estoque");
        const tiposMap = response.reduce((acc, item) => {
          acc[item.id] = item.nome;
          return acc;
        }, {});
        setTiposList(tiposMap);
        setTipos(response);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    getTipos();
  }, []);

  async function getUnidades() {
    try {
      const response = await GenGet(`/retornar_unidades_estoque/${data.id}`);
      setUnidades(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if(refresh){
      getUnidades();
    }
    
  }, [refresh]);

  useEffect(() => {
    getUnidades();
  }, []);

  const handleTipoMov = (id, value) =>{
    setMovFormData({
        ...movFormData,
        tipo: value,
      });
  }

  const handleQtdMov = (e) =>{
    setMovFormData({
        ...movFormData,
        quantidade: e.target.value,
      });
  }
  const [unidadePoiter, setUnidadePointer] = useState(null);
  const [unidadePoiterUpdate, setUnidadePointerUpdate] = useState(null);
  const [updateItemForm, setUpdateItemForm] = useState({
    estoque_tipos_id: '',
    estoque_tipo_itens_id: '',
  })

  const [updateUnidadeForm, setUpdateUnidadeForm] = useState({
    nome: '',
    descricao: '',
  })

  const [tiposItemListUpdate, setTiposItemListUpdate] = useState(null);

  const handleUpdateUnidadeForm = (e) => {
    setUpdateUnidadeForm({
      ...updateUnidadeForm,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleTipoItemIdUpdate = (id, value) => {
    setUpdateItemForm({
      ...updateItemForm,
      estoque_tipo_itens_id: id,
    });
  };

  const handleTipoUpdate = (id, value) => {
    setUpdateItemForm({
      ...updateItemForm,
      estoque_tipos_id:id,
    })
    const tipoEncontrado = tipos.find(item => item.nome === value);
    setTiposItemListUpdate({});
    tipoEncontrado.itens.map((item) => {
      setTiposItemListUpdate((prevTipos) => ({
        ...prevTipos,
        [item.id]: item.valor
      }));
    });
  }

  const handleUnidadeUpdateIdCancel = () =>{
    
    setUpdateItemForm({
      estoque_tipos_id: '',
      estoque_tipo_itens_id: '',
    })

    setUpdateUnidadeForm({
      nome: "",
      descricao: "",
    })
  }

  const handleUnidadeId = (value) =>{
    setUnidadePointer(value);
    setMovFormData({
        ...movFormData,
        estoque_unidade_id: value.id,
      });
  }

  const handleUnidadeUpdateId = (value) =>{
    setUnidadePointerUpdate(value);
    setUpdateItemForm({
      ...updateItemForm,
      estoque_unidade_id:value.id,
    })

    setUpdateUnidadeForm({
      ...updateUnidadeForm,
      nome: value.nome,
      descricao: value.descricao,
    })
  }

  const [scssMsgUpdate, setScssMsgUpdate] = useState(false);

  const handlePostUnidadeUpdate = (id) =>{
    async function postUpdate() {
      try {
        const response = await GenPost(`/atualizar_unidade/${id}`, updateUnidadeForm)
        setScssMsgUpdate(response.message);
        
        handleUnidadeUpdateIdCancel()
        setTimeout(() => {
          setScssMsgUpdate(null);
        }, 3000);
        setRefresh(true);
      } catch (err) {
        setScssMsgUpdate(err.message);
        setTimeout(() => {
          setScssMsgUpdate(null);
        }, 3000);
      }
    }
    postUpdate();
  }

  const handlePostUnidadeUpdateItem = () =>{
    async function postUpdate() {
      try {
        const response = await GenPost(`/cadastrar_rel_unidade_tipo`, updateItemForm)
        setScssMsgUpdate(response.message);
          
        setTimeout(() => {
            setScssMsgUpdate(null);
          }, 2000);
          
        setRefresh(true);

      } catch (err) {
        setScssMsgUpdate(err.message);
        setTimeout(() => {
          setScssMsgUpdate(null);
        }, 3000);
      }
    }
    postUpdate();
  } 

  const handleDeleteTipoItem = (id) =>{
    async function deleteItem() {
      try {
        const response = await GenPost(`/excluir_tipo_estoque_item_rel/${id}`)
        setScssMsgUpdate(response.message);
        
        setTimeout(() => {
          setScssMsgUpdate(null);
        }, 3000);

        setUnidadePointerUpdate((prevState) => ({
          ...prevState,
          itens: prevState.itens.filter(item => item.id !== id),
        }));

        setRefresh(true);

      } catch (err) {
        setScssMsgUpdate(err.message);
        setTimeout(() => {
          setScssMsgUpdate(null);
        }, 3000);
      }
    }
    deleteItem();
  }

  return (
    <div className='p-2 text-[10pt] w-full min-h-[100%]'>
      <h3 className='text-[12pt]'>Estoque composto</h3>
      <hr/>
      <h3 className='text-[12pt]'>{data.nome}</h3>
      <div className='flex flex-row gap-2 items-center align-middle p-2 bg-slate-200 rounded-md'>
        <img src={`${appUrl}/${data.imagens.find(img => img.capa)?.imagem}`} className='w-[80px] h-[80px] rounded-md' />
        <div className='flex flex-col gap-2 p-2 bg-slate-200 rounded-md'>
          <p className='text-[11pt]'>- Descrição: {data.descricao}</p>
          <p className='text-[11pt] text-wrap -mt-4'>- Status: {data.ativo === 1  || data.ativo === true ? 'Ativo' : "Inativo"}</p>
        </div>

      </div>
      
      <div className='mt-4'>
          <p className='font-semibold text-[12pt] mt-2'>Nova Classe de Produto</p>
          
          <div className='flex flex-row gap-2 my-2'>
            <div className='flex flex-col gap-1'>
              <input
                name="nome"
                onChange={handleField}
                id="nome"
                value={classeFormData.nome}
                placeholder='Nome'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
              />
            </div>
            <div className='flex flex-col gap-1'>
              <input
                name="descricao"
                onChange={handleField}
                id="descricao"
                value={classeFormData.descricao}
                placeholder='Descrição'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
              />
            </div>
            <div className='flex flex-col gap-1'>
              <input
                type="number"
                name="quantidade"
                value={classeFormData.quantidade}
                onChange={handleField}
                id="quantidade"
                placeholder='Quantidade'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
              />
            </div>
          </div>

      </div>

      <hr className='z-0'/>
      <div className='flex flex-row gap-2 items-center align-middle'>
          <p className='font-medium text-[11pt]'>Especificações do produto</p>  
          {!showForm &&  <button onClick={() => setShowForm(true)} className="bg-blue-400 hover:bg-blue-500 admin-button">Novo Item</button>}  
      </div>

      {showForm && (
        <div className='flex flex-row gap-2'>
          <CustomSelect data={tiposList} label={'Tipos cadastrados'} onChange={handleTipo} />
          {tiposItemList && <CustomSelect data={tiposItemList} label={'Valor de item'} onChange={handleTipoItemId} />}
          <button onClick={addClasseItem} className="bg-blue-400 hover:bg-blue-500 admin-button">Adicionar</button>
        </div>
      )}

      <div className="mt-4">
        {classeItensFormData.map(item => (
          <div key={item.estoque_tipos_id} className="flex flex-row justify-between items-center border p-2 mb-2 rounded">
            <span>{tiposList[item.estoque_tipos_id]}: {item.estoque_tipo_itens_valor}</span>
            <div>
              <button onClick={() => removeClasseItem(item.estoque_tipos_id)} className="bg-red-400 hover:bg-red-500 admin-button">Remover</button>
            
            </div>
          </div>
        ))}
      </div>

      <div className='w-full flex justify-end'>
      {msg ? 
        <Toast msg={msg} type='info'/>
      : null}
      
      <button onClick={handlePost} className="bg-blue-400 hover:bg-blue-500 admin-button">Salvar</button>

      </div>

      <hr className='z-0'/>

      <p className='font-semibold text-[12pt] mt-2'>Classes de Produto</p>
      <div className='w-full mt-2'>
        {unidadePoiterUpdate && (
            <div className='rounded-md bg-slate-200 gap-2 p-2 my-2 flex flex-col'>    
              <div className='flex flex-row gap-2 my-2'>
                <div className='flex flex-col gap-1'>
                  <input
                    name="nome"
                    onChange={handleUpdateUnidadeForm}
                    id="nome"
                    value={updateUnidadeForm.nome}
                    placeholder='Nome'
                    className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <input
                    name="descricao"
                    onChange={handleUpdateUnidadeForm}
                    id="descricao"
                    value={updateUnidadeForm.descricao}
                    placeholder='Descrição'
                    className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                  />
                </div>
                <button className='bg-red-400 hover:bg-red-300 p-1 text-[10pt] rounded-md w-[80px] h-[30px] flex justify-center align-middle items-center text-slate-100' onClick={handleCancelMov}>Cancelar</button>
                <button className='bg-blue-400 hover:bg-blue-300 p-1 text-[10pt] rounded-md w-[80px] h-[30px] flex justify-center align-middle items-center text-slate-100' onClick={()=>handlePostUnidadeUpdate(unidadePoiterUpdate.id)}>Atualizar</button>

              </div>

              <p className='text-[11pt] font-medium'>Especificações:</p>
              
              {unidadePoiterUpdate.itens.map((item)=>{
                return(
                  <div className="bg-slate-100 p-2 mt-2 flex items-center rounded-md relative min-w-[100px] h-[40px] pt-4">
                  <p className='text-[10pt] pl-2' key={item.id}>- {item.tipo.nome} : {item.valor.valor}</p>
                  <span className="p-1 rounded-full bg-red-400 text-slate-100 absolute -top-2 -right-1 h-[20px] w-[20px] items-center flex justify-center cursor-pointer" onClick={()=>handleDeleteTipoItem(item.id)}>x</span>
                  </div>
                  
                )
              })}
            <div className='flex flex-row gap-2 mt-2 z-1'>
            <CustomSelect data={tiposList} label={'Tipos cadastrados'} onChange={handleTipoUpdate} />
            {tiposItemListUpdate && <CustomSelect data={tiposItemListUpdate} label={'Valor de item'} onChange={handleTipoItemIdUpdate} />}
            <button onClick={()=>handlePostUnidadeUpdateItem(unidadePoiterUpdate.produto_id)} className="px-4 py-2 bg-blue-500 text-white rounded h-[30px] flex justify-center align-middle items-center">Adicionar</button>
            {scssMsgUpdate ? 
              <Toast msg={scssMsgUpdate} type='info'/>
            : null}
          </div>
            </div>
          )
        }
      
      </div>
      
      {unidadePoiter && (
          <div className='flex flex-col gap-2 p-2 my-2 bg-slate-200 rounded-md'>
            <p className='text-[11pt] pl-4 font-medium'>{unidadePoiter.nome}</p>
          <p className='text-[11pt] pl-4 -mt-4'>Quantidade em estoque: {unidadePoiter.quantidade}</p>
            <div>
              <p className='text-[11pt] font-medium pl-4'>  Movimentar estoque:</p>
              <div className='flex flex-row p-2 gap-2'>
              <CustomSelect data={{1:'entrada', 2:'saida'}} label={'Tipo'} onChange={handleTipoMov}/>
              <div  className='flex flex-col gap-1'>
                  <input 
                      type="number"
                      name="quantidade"
                      value={movFormData.quantidade}
                      onChange={handleQtdMov}
                      id="quantidade"
                      placeholder='Quantidade'
                      className="px-4 py-2 rounded border border-gray-300 focus:outline-none"/>
              </div>
              <button className='w-[100px] p-1 bg-blue-400 hover:bg-blue-300 shadow-md text-slate-100 font-semibold py-2 rounded border border-gray-300 focus:outline-none h-[30px] flex justify-center align-middle items-center' onClick={handleSubmitMov}>Confirmar</button>
              <button className='w-[100px] p-1 bg-red-400 hover:bg-red-300 shadow-md text-slate-100 font-semibold py-2 rounded border border-gray-300 focus:outline-none h-[30px] flex justify-center align-middle items-center' onClick={handleCancelMov}>Cancelar</button>
              </div>
              {movMsg ? 
                <Toast msg={movMsg} type='success'/>
              : null}
            </div>
          </div> 
          )}

      <div className='flex flex-row gap-2 overflow-scroll flex-wrap no-scrollbar'>
      {unidades && unidades.map((item)=>{
        return (
          <div className='flex flex-col rounded-md bg-slate-200 p-2 justify-between' key={item.id}>
            <div className='flex flex-col'>
            <p className='text-[11pt] font-medium'>{item.nome}</p>
            <p className='text-[10pt] -mt-4 pl-2'>{item.descricao}</p>
            <p className='text-[11pt] font-medium'>Quantidade em estoque: {item.quantidade}</p>
            </div>
            <div className='flex flex-col flex-grow'>
            <p className='text-[11pt] font-medium -mt-4'>Especificações:</p>
              {item.itens.map((item)=>{
                return(
                  <p className='text-[10pt] -mt-4 pl-2' key={item.id}>- {item.tipo.nome} : {item.valor.valor}</p>
                )
              })}
            </div>

            <div className='w-[100%] flex justify-end gap-2'>
              <button className='bg-blue-400 hover:bg-blue-300 p-1 text-[10pt] rounded-sm w-[80px]' onClick={()=>(handleUnidadeId(item))}>Movimentar</button>
              <button className='bg-blue-400 hover:bg-blue-300 p-1 text-[10pt] rounded-sm w-[80px]'  onClick={()=>(handleUnidadeUpdateId(item))}>Editar</button>
            </div>
          </div>
        )
      })}
      
      </div>

    </div>
  );
}

export default MovimentoEstoqueComposto;
