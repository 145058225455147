import React , { useState, useEffect } from "react";
import { Box,
   Typography,
   TextField,
  Button,
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
import Toast from "Admin/components/Toast";
const token = localStorage.getItem('id');

function AtualizarEnd(props) {
  const id = props.id;
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const estados = {
    "AC":"Acre",
    "AL":"Alagoas",
    "AP":"Amapá",
    "AM":"Amazonas",
    "BA":"Bahia",
    "CE":"Ceara",
    "DF":"Distrito Federal",
    "ES":"Espírito Santo",
    "GO":"Goiás",
    "MA":"Maranhão",
    "MT":"Mato Grosso",
    "MS":"Mato Grosso do Sul",
    "MG":"Minas Gerais",
    "PA":"Pará",
    "PB":"Paraíba",
    "PR":"Paraná",
    "PE":"Pernambuco",
    "PI":"Piauí",
    "RJ":"Rio de Janeiro",
    "RN":"Rio Grande do Norte",
    "RS":"Rio Grande do Sul",
    "RO":"Rondônia",
    "RR":"Roraima",
    "SC":"Santa Catarina",
    "SP":"São Paulo",
    "SE":"Sergipe",
    "TO":"Tocantins",
  }
  const [formData, setFormData] = useState({
    tipo:"principal",
    endereco:"",
    complemento:"",
    cep:"",
    cidade:"",
    estado:"",
    codigo_pais:"BRL",
    numero:""

  });
  useEffect(()=>{}, [formData])
  useEffect(() => {
    if(id) { async function getEnd() {
        try {
          const response = await GenGet(`api/uendcliente/${id}`);
          setFormData({
              ...formData,
              tipo:response.endereco.tipo,
              endereco:response.endereco.endereco,
              cep:response.endereco.cep,
              complemento:response.endereco.complemento,
              cidade:response.endereco.cidade,
              estado:response.endereco.estado,
              numero:response.endereco.numero
            });
          setIsLoading(false);
        } catch (err) {

        }
      }
      getEnd();}
    
  }, [id]);

  const setEnd = (e) => {
    setFormData({
      ...formData,
      endereco: e.target.value,
    });
  };
  const setNumero = (e) => {
    setFormData({
      ...formData,
      numero: e.target.value,
    });
  };
  const setComp = (e) => {
    setFormData({
      ...formData,
      complemento: e.target.value,
    });
  };
  const setCep = (e) => {
    setFormData({
      ...formData,
      cep: e.target.value,
    });
  };
  const setCidade = (e) => {
    setFormData({
      ...formData,
      cidade: e.target.value,
    });
  };
  const setEstado = (e) => {
    setFormData({
      ...formData,
      estado: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await GenPut(`api/uendcliente/${id}`, formData);
      console.log(response);
      setMsg(response.message);
    
    } catch (error) {
      setErrMsg(error.message);
    }
  };

  if(!isLoading){  

    return (
      <Box sx={{ p: 1, m: 1, width: {md:"30vw"}}}>
          <div className='w-full flex flex-row justify-center my-1 h-[50px]'>
          {errMsg ? <Toast msg={errMsg} type='error'/>
            : null}
        
          {msg ? <Toast msg={msg} type='success'/>
            : null}
          </div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p="0.25rem 1.25rem"
        borderRadius="0.55rem"
        sx={{ p: 1, m: 2, width:'100%'}}
      >
        <Box sx={{p:0}}>
          <Typography variant="h5">
              Dados de endereco:     
          </Typography><hr/>
  
          <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  defaultValue={formData.endereco}
                  id="endereco"
                  label="Rua :"
                  name="endereco" 
                  onChange={setEnd} />       
            </Box>
            <Box sx={{marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  defaultValue={formData.numero}
                  id="numero"
                  label="Número :"
                  name="numero" 
                  onChange={setNumero} />       
            </Box>
            <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  defaultValue={formData.complemento}
                  id="complemento"
                  label="Complemento :" 
                  name="complemento" 
                  onChange={setComp} />       
            </Box>
            <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  defaultValue={formData.cep}
                  id="cep" 
                  label="CEP :"
                  name="cep" 
                  onChange={setCep} />       
            </Box>
            <Box sx={{p:0,marginTop:2,width:'100%'}}>
              <TextField  fullWidth 
                  variant="standard"
                  defaultValue={formData.cidade}
                  id="cidade" 
                  label="Cidade :"
                  name="cidade" 
                  onChange={setCidade} />       
            </Box>
            <Box sx={{p:0,marginTop:2,width:'100%'}}>
            <FormControl sx={{marginTop:2 ,width:'100%'}}>
            <InputLabel>Estado</InputLabel>
            <Select
              variant="standard"
              value={formData.estado}
              onChange={setEstado}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {Object.keys(estados).map((sigla) => (
                <MenuItem key={sigla} value={sigla} >
                  {estados[sigla]}
                </MenuItem>
              ))}
            </Select>
            </FormControl>  
            </Box>
            <Box sx={{p:0,m:1, marginTop:2, width:'100%'}} display='flex' flexDirection={'row-reverse'}>
            <Button variant="contained" onClick={handleSubmit} sx={{p:1,m:1}}>ALTERAR</Button>      
            </Box>
            
          </Box>
      </Box>      
    </Box>

  
    );
  } else{
    <> Carregando ...</>
  }
  
};
export default AtualizarEnd;