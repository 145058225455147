import React, { useState, useEffect } from 'react';
import { Box, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PainelFotos from 'Admin/scenes/produtos/PainelFotos';
import GenericModal from './GenericModal';

const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const AdminImageSlider = (props) => {
  const images = props.images;
  const id = props.id;
  const [modalSate, setModalState]= useState({
    active:false,
    component: PainelFotos,
    pointer:id
  });
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  
  const handleImageLoad = () => {
    setLoading(false);
  };

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const selectImage = (index) => {
    setActiveImageIndex(index);
  };

  useEffect(() => {
    // Reset the index when images change
    setActiveImageIndex(0);
  }, [images]);

  useEffect(() => {
    // Ensure activeImageIndex stays within valid range
    if (activeImageIndex < 0) {
      setActiveImageIndex(images.length - 1);
    } else if (activeImageIndex >= images.length) {
      setActiveImageIndex(0);
    }
  }, [activeImageIndex, images]);

  return (
    <div className='h-[100%] text-[10pt]'>
        <GenericModal
          Component={modalSate.component}
          pointer={modalSate.pointer}
          active={modalSate.active}
          setActive={(active) => setModalState({ ...modalSate, active })}
          setRefresh={props.setRefresh}
        /> 
      {images && images.length > 0 ? ( // Verifica se images está definido e tem pelo menos um elemento
        <div className="flex flex-col items-center w-[100%]">
          {/* Main Image */}
          <div className="relative max-w-[350px] max-h-[400px] overflow-hidden rounded-md">
          <img
              className='shadow-lg'
              src={`${GLOBAL_URL}/${images[activeImageIndex].imagem}`}
              style={{
                display: loading ? 'none' : 'block',
                width: '100%',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%'
              }}
              onLoad={handleImageLoad}
              alt={`Image ${activeImageIndex + 1}`}
            />

            {/* Navigation Buttons */}
            <Box
              display="flex"
              justifyContent="space-between"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              width="100%"
            >
              <IconButton
                onClick={prevImage}
                style={{ backgroundColor: 'transparent', color: 'white', zIndex: 2 }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                onClick={nextImage}
                style={{ backgroundColor: 'transparent', color: 'white', zIndex: 2 }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </div>

          {/* Thumbnails */}
          <div className="w-full flex flex-row flex-wrap justify-start mt-1 shadow-md overflow-x-scroll no-scrollbar">
            {images.map((image, index) => (
              <img
                className="h-[80px] m-1"
                key={index}
                src={`${GLOBAL_URL}/${image.imagem}`}
                alt={`Thumbnail ${index + 1}`}
                style={{ cursor: 'pointer' }}
                onClick={() => selectImage(index)}
              />
            ))}
          </div>
          <div className='flex w-[100%] justify-end pt-2'>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={()=>setModalState({...modalSate,active: true})}>editar fotos</button>
          </div>
        </div>
      ) : (
        <CircularProgress color="success" sx={{ marginTop: '150px' }} />
      )}
    </div>
  );
};

export default AdminImageSlider;






