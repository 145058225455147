import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { CircularProgress } from '@mui/material';
import Toast from 'Admin/components/Toast';

function PainelCaixas() {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [data, setData]=useState({});
    const [msg, setMsg] = useState(null);
  
    const [formData, setFormData]= useState({
        'nome':'',
        'peso':'',
        'largura':'',
        'comprimento':'',
        'altura':'',
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
    
    const handlePost = () => {
      async function postData() {
        try {
          const response = await GenPost('/caixas/store', formData);
          setMsg(response.message)
          setTimeout(() => {
            setMsg(null);
          }, 3000);
          setRefresh(true);
          setFormData({
            'nome':'',
            'peso':'',
            'largura':'',
            'comprimento':'',
            'altura':'',
          })
        } catch (err) {
          setMsg(err.message)
          setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }
      postData();
    };

    const handleDelete = (id) => {
      async function postData() {
        try {
          const response = await GenPost(`/caixas/destroy/${id}`);
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
          setRefresh(true);

        } catch (err) {
          setMsg(err.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }
      postData();
    }; 
    
    async function getCaixas() {
      try {
        const response = await GenGet('/caixas/index');
        // Ensure frete_isento is a boolean
        setData(response);
        setLoading(false);
        setRefresh(false);
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  
    useEffect(() => {
        getCaixas();
    }, []);
    
    useEffect(() => {
      if (refresh){
        getCaixas();
      }
      
    }, [refresh]);
  
if (loading){
    return(
      <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
        <CircularProgress color="success"/>
      </div>    
    )
  } else {
    return (
      <div className='rounded-md flex flex-col w-full bg-slate-50 my-4 text-[10pt]'>
          <h3 className='text-[12pt]'>Configurações de Caixas</h3>
          <div className='rounded-md flex flex-col gap-2 p-2'>
          <h3 className='input-classes text-sm'>Nova Caixa</h3>
          <div  className='flex flex-col gap-1'>
          <input 
              type="text"
              name="nome"
              onChange={handleChange}
              id="nome"
              placeholder='Nome'
              className="px-4 w-[200px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
          </div>


          <div  className='flex flex-row flex-wrap gap-1'>
            <input 
              type="float"
              name="peso"
              onChange={handleChange}
              id="peso"
              placeholder='Peso(kg)'
              className="px-4 w-[150px] py-2 rounded border border-gray-300 focus:outline-none"/>

            <input 
              type="float"
              name="largura"
              onChange={handleChange}
              id="largura"
              placeholder='Largura(cm)'
              className="px-4 w-[150px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
          
            <input 
              type="float"
              name="comprimento"
              onChange={handleChange}
              id="comprimento"
              placeholder='Comprimento(cm)'
              className="px-4 w-[180px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
            
            <input 
              type="float"
              name="altura"
              onChange={handleChange}
              id="altura"
              placeholder='Altura(cm)'
              className="px-4 w-[150px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>

          </div>
          <div className='w-full flex flex-row-reverse'>
          <button className='admin-button bg-blue-500 hover:bg-blue-400' onClick={handlePost}>Cadastrar</button>    
          </div>
          {msg ? 
              <Toast msg={msg} type='success'/>
            : null} 

        <div className=' mt-4 flex w-full flex-col'>
          <h3 className='input-classes text-sm'>Caixas cadastradas</h3>

          {!loading && data && data.map(item =>(
          <div className='w-full border-1 p-1 rounded-md border-slate-700 m-1 h-[50px] flex flex-row align-middle items-center justify-between'>
            <p className='text-[10pt] font-semibold'>Nome: <span className='font-medium'>{item.nome}</span></p>
            <p className='text-[10pt] font-semibold'>Peso: <span className='font-medium'>{item.peso}</span></p>
            <p className='text-[10pt] font-semibold'>Altura: <span className='font-medium'>{item.altura}</span></p>
            <p className='text-[10pt] font-semibold'>Largura: <span className='font-medium'>{item.largura}</span></p>
            <p className='text-[10pt] font-semibold'>Comprimento: <span className='font-medium'>{item.comprimento}</span></p>
            <button className='admin-button bg-red-500 hover:bg-red-400' onClick={()=>handleDelete(item.id)}><p className='p-0 m-0 text-slate-100 font-semibold text-[11pt]'>Remover</p></button>    
          </div>
          ))}

        </div>
        </div>
      </div>
    )
  }
}

export default PainelCaixas