import React, {useState, useEffect} from 'react'
import{
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  } from "@mui/material";
  import LocalShippingIcon from '@mui/icons-material/LocalShipping';
  import LocationOnIcon from '@mui/icons-material/LocationOn';
  import AccountCircleIcon from '@mui/icons-material/AccountCircle';
  import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { Card } from 'react-bootstrap';
import { GenGet } from 'Loja/state/api';
import {makeStyles} from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

  const userId = localStorage.getItem('id');  

  const useStyles = makeStyles({
    MenuDiv: {
      "&:hover": {
        backgroundColor: "#ECEEF0",
        fontSize:'100px',
        transition: 'background-color 0.3s ease-in-out',
        cursor: 'pointer'
      }
    }
  });
function ContaUsr() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [user, setUser] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [ carregando, setCarregando] = useState(true);
  const [ checked, setChecked] = useState(false);
  const [ message, setMessage] = useState('');
  
  function check(){
    if(Object.keys(cliente).length > 0 ){
        setMessage('');
        setChecked(true);
    } else {
        setMessage('Cadastre seus dados.');
        setChecked(true);  
    }
  }

  async function getUser(userId) {
    try {
      const response = await GenGet(`api/user/${userId}`);
      const clienteData = await GenGet(`api/ucliente/${response.cliente.id}`)
      setUser(response);
      setCliente(clienteData.cliente);
    } catch (err) {

    }
    check();
  }

  useEffect(() => {
    async function fetchData() {
      await getUser(userId);
      setCarregando(false);
    }
    fetchData();
  }, [userId]);

  return (
    <Box className='container' sx={{padding:5}}>

      <Typography variant='h5'>
        Minha Conta
      </Typography>
      {carregando ? (<> Carregando...</>) 
      : (
      <>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card className='shadow'>
            <Box sx={{padding:1}} height={'40vh'}>
              <Typography variant='h6'>Dados Pessoais</Typography>
              <hr/>
                            
                {checked ? (             
                <Box display={'flex'} flexDirection='column' sx={{marginLeft:1}}>
                  <Typography  sx={{marginTop:1}}>Nome : {cliente.nome} {cliente.sobrenome} </Typography>
                  <Typography sx={{marginTop:1}}>Telefone: {cliente.telefone}</Typography>
                  <Typography sx={{marginTop:1}}>CPF: {cliente.cpf}</Typography>
                  <Typography sx={{marginTop:1}}>Status: {cliente.status}</Typography>  
                </Box>) : (<>{message}</>) }
            </Box>          
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card className='shadow'>
            <Box sx={{padding:1}} height={'40vh'}>
              <Typography variant='h6'>Configurações</Typography>
              <hr/>
              <Box display={'flex'} flexDirection='column' sx={{marginLeft:1}} >
                <Box display={'flex'} className={classes.MenuDiv} flexDirection='row' sx={{marginTop:1}} alignItems="center" onClick={() => {navigate('/meuperfil')}}><AccountCircleIcon sx={{ marginRight:2}} /><Typography sx={{ marginleft:2, fontSize:'14pt'}}>Perfil </Typography></Box>
                <Box display={'flex'} className={classes.MenuDiv} flexDirection='row' sx={{marginTop:1}} alignItems="center"><ChromeReaderModeIcon sx={{ marginRight:2}}/><Typography sx={{ marginleft:2, fontSize:'14pt'}}>Conta </Typography></Box>
                <Box display={'flex'} className={classes.MenuDiv} flexDirection='row' sx={{marginTop:1}} alignItems="center"><LocationOnIcon sx={{ marginRight:2}}/><Typography sx={{ marginleft:2, fontSize:'14pt'}}>Endereços </Typography></Box>
                <Box display={'flex'} className={classes.MenuDiv} flexDirection='row' sx={{marginTop:1}} alignItems="center"><LocalShippingIcon sx={{ marginRight:2}}/><Typography sx={{ marginleft:2, fontSize:'14pt'}}>Pedidos </Typography></Box>     
              </Box>
            </Box>
          </Card>
        </Grid>

    </Grid>
      
      </> )}


    </Box>
  )
}

export default ContaUsr


