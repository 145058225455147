import {useEffect, useState} from 'react';
import axios from "Admin/state/axios";
import{ Alert} from "@mui/material";
import Toast from 'Admin/components/Toast';
import SubmitButton from 'Admin/components/SubmitButton';

function PainelMercadoPago() {
  const [scssMsg , setScssMsg] = useState(false);
  const [postIsLoading, setPostIsLoading ] = useState(false);
  const [formData, setFormData] = useState({
    application: "mercadopago",
    token:"",
    expires:"2592000",
    public_token:""
  });

  const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }

    const handleSubmit = async (event) => {
    event.preventDefault();
    setPostIsLoading(true);

    try {
      const response = await axios.post('tokens/create', formData);
      setScssMsg(response.data.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 5000);
      setPostIsLoading(false);

    } catch (error) {
      setScssMsg(error.message)
      setTimeout(() => {
        setScssMsg(null);
      }, 5000);
      setPostIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col gap-1 p-2 w-full text-[10pt]'>
      <h3 className='text-[14pt]'>Painel melhor envio</h3>
      <p className='text-[9pt] -mt-2'>painel de integração melhor envio.</p>
      <hr/>
      <div className='flex flex-wrap justify-between gap-2'>
          <div className='bg-white m-[4px] flex-grow'>
            <p className='p-0 m-0'> Esse é o Painel do Mercado Pago, para obter seu token é necessário ir ate a plataforma do mercado pago.
            <br/>Faça seu login com suas credenciais e vá até <b>APLICATIVOS - AUTORIZAÇOES - TOKEN</b> , 
            <br/>crie um token e copie o conteudo gerado e cole aqui no campo <b>TOKEN</b>  </p>
          </div>
          <div className='flex flex-col justify-around gap-1 mt-6'>   
          <p><b>Insira seu token do Melhor Envio</b></p>

        <div className='flex flex-col gap-1 flex-grow'>
        <input
        className="px-4 py-2 rounded border border-gray-300 focus:outline-none w-full min-w-[300px]"
            label="token:"
            id="token"
            name="token"
            onChange={handleChange}
            />
        <input
m
        className="px-4 py-2 rounded border border-gray-300 focus:outline-none min-w-[300px] w-full"
            label="token público:"
            id="public_token"
            name="public_token"
            onChange={handleChange}
            />
            <div className='flex flex-row-reverse'>
            <SubmitButton isLoading={postIsLoading} onClick={handleSubmit}>
              Confirmar
            </SubmitButton>
            
            </div>
            
            {scssMsg ? 
              <Toast msg={scssMsg} type='success'/>
            : null}     
        </div>

        </div>

        </div>
      
  </div>
  )
}

export default PainelMercadoPago