import { CircularProgress, Dialog, DialogContent } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { GenGet } from 'Admin/state/api';
import NovoUsuarioCliente from '../forms/clientes/NovoUsuarioCliente'
import Orcamentos from '../clientes/Orcamentos';
import CustomSelect from 'Admin/components/CustomSelect';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import FinalizarVenda from './FinalizarVenda';
import NovoUsuario from '../forms/usuarios/NovoUsuario';
import Toast from 'Admin/components/Toast';

function PainelVendaAvulsa() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [msg , setMsg] = useState(null);
  const [produtoMsg , setProdutoMsg] = useState(null);
  const [clienteAberto, setClienteAberto] = useState(false);
  const [orcamentoAberto, setOrcamentoAberto] = useState(false);
  const [finalizarAberto, setFinalizarAberto] = useState(false);
  const [clienteLookup, setClienteLookup] = useState([]);
  const [produtoLookup, setProdutoLookup] = useState([]);
  const [produto, setProduto] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [unidadesEstoque, setUnidadesEstoque] = useState(null);

  const [produtoSelecionado, setProdutoSelecionado] = useState({
    id:0,
    nome:'Selecione um produto',
    tipo:0,
    tipo_nome : 'padrão',
    quantidade:1,
    valor:0.00,
    total:0.00
  })

  const [ clienteSelecionado, setClienteSelecionado] = useState({
    nome:'Selecione um cliente',
    id:0,
  })

  const [formData, setFormData] = useState({
    cliente_id: '',
    produtos: []
  })

  const total = formData.produtos.reduce((acc, item) => {
    const valor = parseFloat(item.valor) || 0; 
    const quantidade = parseInt(item.quantidade) || 0;
    return acc + valor * quantidade;
  }, 0).toFixed(2);

  const handleClose = () => {
    setClienteAberto(false);
    setOrcamentoAberto(false);
    setFinalizarAberto(false);
  };

  const handleOpenModalCliente = () => {
    setClienteAberto(true);
  };

  const handleOpenModalOrcamento = () => {
    setOrcamentoAberto(true);
  }

  const handleOpenModalFinalizar = () => {
    if (formData.cliente_id === 0 || formData.produtos.length < 1){
        setMsg('Preencha todos os campos');
        setTimeout(() => {
            setMsg(null);
          }, 3000);

      return
    }
    setFinalizarAberto(true);
  };

  const handleCliente = (id, value) => {
    console.log(id, value)
    setClienteSelecionado({
      nome:value,
      id:id,
    });

    setFormData({
      ...formData,
      cliente_id:id
    });
  };

  const handleProduto = (id, value) => {
    setProdutoSelecionado({
      nome: value,
      id:id,
      tipo:0,
      tipo_nome : 'padrão',
      quantidade:1,
    })
  };

  const handleProdutoQuantidade = (e) => {
    setProdutoSelecionado({
      ...produtoSelecionado,
      quantidade:e.target.value
    })
  };

  const handleAdicionarProduto = () => {
    
    if (produto.estoque_composto === 1 || produto.estoque_composto === true && produtoSelecionado.tipo === 0 || produtoSelecionado.tipo === false ){
      setProdutoMsg('Escolha um tipo de produto');
      setTimeout(() => {
        setProdutoMsg(null);
        }, 3000);
      return;
    }
    
    setFormData((prevState) => {
      const produtoValor = parseFloat(produto.valor) || 0;
      const produtoQuantidade = parseInt(produtoSelecionado.quantidade) || 0;
      const subtotal = parseFloat((produtoValor * produtoQuantidade).toFixed(2));
  
      const existingProductIndex = prevState.produtos.findIndex((p) => {
        // Se o produto for composto, verifica id e tipo
        if (produto.estoque_composto === 1 || produto.estoque_composto === true) {
          return p.id === produtoSelecionado.id && p.tipo === produtoSelecionado.tipo;
        }
        // Para produtos não compostos, verifica somente o id
        return p.id === produtoSelecionado.id;
      });

  
      if (existingProductIndex !== -1) {
        // Atualizar o produto existente
        const updatedProdutos = [...prevState.produtos];
        updatedProdutos[existingProductIndex].quantidade = produtoSelecionado.quantidade;
        updatedProdutos[existingProductIndex].valor_total = subtotal;
        return {
          ...prevState,
          produtos: updatedProdutos,
        };
      } else {
        // Adicionar novo produto
        const novoProduto = {
          ...produtoSelecionado,
          valor: parseFloat(produtoValor.toFixed(2)),
          valor_total: subtotal,
        };
        return {
          ...prevState,
          produtos: [...prevState.produtos, novoProduto],
        };
      }
    });
  };

  const handleUnidades = (id, value) =>{
    setProdutoSelecionado({
      ...produtoSelecionado,
      tipo: id,
      tipo_nome : value
    });
  }

  const handleRemoverProduto = (id) => {
    setFormData((prevState) => ({
      ...prevState,
      produtos: prevState.produtos.filter((produto) => produto.id !== id),
    }));
  };

  const limparTela = () =>{
    setProduto(null)
    setCliente(null)
    setClienteAberto(null)
    setOrcamentoAberto(null)
    setFinalizarAberto(null)
    setProdutoLookup([])
    setUnidadesEstoque(null)
    setFormData({
      cliente_id: '',
      produtos: []
    })
    setProdutoSelecionado({
      id:0,
      nome:'Selecione um produto',
      tipo:0,
      tipo_nome : 'padrão',
      quantidade:1,
      valor:0.00,
      total:0.00
    });
    setClienteSelecionado({
      nome:'Selecione um cliente',
      id:0,
    })
    nav('vendas/ref')

  }

  useEffect(() => {

      async function getRequisitos() {
        try {
          const response = await GenGet(`vendaavulsa/requisitos`);
          setClienteLookup(response.clientes);
          setProdutoLookup(response.produtos);
          setIsLoading(false);
        } catch (err) {

        }
      }

      getRequisitos();
  }, []);

  useEffect(() => {

    async function getCliente(id) {
      try {
        const response = await GenGet(`vendaavulsa/cliente/${id}`);
        console.log(response.cliente)
        setCliente(response.cliente)
      } catch (err) {

      }
    }

    if (clienteSelecionado.id !== 0 ){
      getCliente(clienteSelecionado.id);
    }
    
}, [clienteSelecionado]);

useEffect(() => {
  async function getProduto(id) {
    try {
      const response = await GenGet(`vendaavulsa/produto/${id}`);
      setProduto(response);

      // Verifica se o estoque é composto e processa as unidades
      if (response.estoque_composto === 1 || response.estoque_composto === true) {
        const unidadesEstoque = response.estoque.unidade_estoque.reduce((acc, item) => {
          acc[item.id] = item.descricao;
          return acc;
        }, {});
        console.log(unidadesEstoque);
        setUnidadesEstoque(unidadesEstoque);
      }
    } catch (err) {
      console.error('Erro ao buscar produto:', err);
    }
  }

  if (produtoSelecionado.id !== 0) {
    getProduto(produtoSelecionado.id);
  }
}, [produtoSelecionado]);


  const cols = ['Id', 'Nome', 'ID Tipo', 'Nome Tipo','Quantidade', 'Valor', 'Valor Total'];
  const actions = [{ action: 'Remover', function: handleRemoverProduto }];
  
  return (
    <div className='mt-[20px] flex flex-col gap-1 relative'>
    <h3 className='text-[14pt]'>Painel do Vendedor</h3>
    <p className='text-[9pt] -mt-2'>Execute uma nova venda.</p>
    <hr/>
      <Dialog open={clienteAberto} onClose={() => handleClose()} maxWidth="xl" sx={{
        '& .MuiDialog-paper': {
          minWidth: '70%',
        },
      }}>
          <DialogContent>
            <NovoUsuario />
          </DialogContent>
      </Dialog>

      <Dialog open={orcamentoAberto} onClose={() => handleClose()} maxWidth="xl" sx={{
        '& .MuiDialog-paper': {
          minWidth: '70%',
        },
      }}>
          <DialogContent>
            <Orcamentos />
          </DialogContent>
      </Dialog>

      <Dialog open={finalizarAberto} onClose={() => handleClose()} maxWidth="xl" sx={{
        '& .MuiDialog-paper': {
          minWidth: '70%',
        },
      }}>
          <DialogContent>
            <FinalizarVenda data={formData} close={limparTela}/>
          </DialogContent>
      </Dialog>
    <div className={'flex flex-row-reverse absolute right-0 top-0 gap-2'}>
      <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleOpenModalCliente} >Novo Cliente</button>
    </div>

    {!isLoading ? (
        <div className='flex flex-col gap-1'>
          <div className='flex flex-row gap-2'>
            {clienteLookup && <CustomSelect data={clienteLookup} label={'Cliente'} className={'min-w-[200px]'} onChange={handleCliente} search={true}/> }
            {produtoLookup && <CustomSelect data={produtoLookup} label={'Produto'}  className={'min-w-[200px]'} onChange={handleProduto} search={true} />}
          </div>
            <div className='flex flex-row gap-1 p-2 pt-4 rounded-sm border w-full'>
              {!cliente ? (
                <p>Selecione um cliente</p>
              ) : (
                <div className='flex flex-row gap-2'>
                  <p>{cliente && cliente.nome && (`Nome: ${cliente.nome} ${cliente.sobrenome} - `)}</p>
                  <p>{cliente && cliente.cpf && (`CPF: ${cliente.cpf}`)}</p>
                </div>
              )}

            </div>
            
            <div className='flex p-2 pt-4 rounded-sm border w-full'>
              
              {!produto ? ( 
                <p className='flex'>Selecione um produto</p>
              ) : (
                <div className={'flex flex-col p-2'}>
                  {produto.estoque_composto === 0 || produto.estoque_composto === false ? (
                    <div className={'flex flex-col p-2'}>
                      <div className='flex flex-col gap-1'>
                        <p>{produto && produto.nome && (`Nome: ${produto.nome}`)}</p>
                      </div>
                      <div className='flex flex-row gap-2 align-middle items-center'>
                        <div className='flex flex-col gap-1'>
                          <input
                              name="quantidade"
                              onChange={handleProdutoQuantidade}
                              id="quantidade"
                              type='number'
                              value={produtoSelecionado.quantidade}
                              placeholder='Quantidade'
                              className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                            />
                        </div>
                        <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handleAdicionarProduto}>Adicionar</button>
                      </div>
                    </div>
                  ) : (
                    <div className={'flex flex-col p-2'}>
                      <div className='flex flex-col gap-1'>
                        <p>{produto && produto.nome && (`Nome: ${produto.nome}`)}</p>
                      </div>
                      {produto && unidadesEstoque && (        
                          <div className='flex flex-row gap-2 align-middle items-center'>
                            <CustomSelect data={unidadesEstoque} label={'Tipo'} className={'min-w-[200px]'} onChange={handleUnidades} search={true}/>
                            <div className='flex flex-col gap-1'>
                              <input
                                  name="quantidade"
                                  onChange={handleProdutoQuantidade}
                                  id="quantidade"
                                  type='number'
                                  value={produtoSelecionado.quantidade}
                                  placeholder='Quantidade'
                                  className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
                                />
                            </div>
                            <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handleAdicionarProduto}>Adicionar</button>
                            {produtoMsg ? 
                              <Toast msg={produtoMsg} type='success'/>
                            : null}                            

                          </div>
                      )}
                      

                    </div>

                  )}

                </div>
              )}
            </div>
            <GenericDataGrid rows={formData.produtos} columns={cols} per_page={25} actions={actions} title="Pedido"/>
            <div className='flex flex-col gap-1 p-2 pt-4 rounded-sm border w-full'>
              <div>
                <p className='font-semibold'>Finalização de venda</p>
              </div>
              <div className='flex flex-row justify-between p-2'>
                <p><span className='font-semibold'>Total:</span> R${total}</p>
                <div className='flex gap-2'>
                {msg ? 
                  <Toast msg={msg} type='info'/>
                : null}
                {/* 
                  <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handleOpenModalOrcamento}>Gerar Orçamento</button>
                */}                  
                
                <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handleOpenModalFinalizar}>Finalizar</button>

                </div>
              </div>
            </div>

          
        </div>

      ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      )}
    </div>
  )
}

export default PainelVendaAvulsa