import React, { useState, useEffect, useContext } from 'react';
import { initMercadoPago, Payment, StatusScreen  } from '@mercadopago/sdk-react';
import { GenPost, GenGet } from 'Loja/state/api';
import { CartContext } from 'Ecommerce';
import { CircularProgress } from '@mui/material';

export default function MercadoPago(props) {
  const ordem_valor = props.ordem_valor;
  const ordem_id = props.ordem_id;
  const [isLoading, setIsLoading] = useState(true);
  const [ordem_mp, setordem_mp] = useState(null);
  const [StatusInit, setStatusInit] = useState(null);
  const { appUrl, newCart  } = useContext(CartContext);
  const [PUBLIC_KEY, SET_PUBLIC_KEY] = useState(null);


  async function getToken() {
    try {
      const response = await GenGet(`api/mp-public-tkn`);
      SET_PUBLIC_KEY(response);
    } catch (err) {
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getToken();
  }, []);

  useEffect(() => {
    if (PUBLIC_KEY){
      initMercadoPago(PUBLIC_KEY, {locale: 'pt-BR'});
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }

  }, [PUBLIC_KEY]);




  useEffect(() => {
      if(ordem_mp && PUBLIC_KEY){
        setStatusInit({
          paymentId: ordem_mp,      
        });
        newCart([])
      };
  }, [ordem_mp]);
  
  const initialization = {
    amount: ordem_valor,
    }
  
  const statusCustomization = {
    backUrls: {
      'error': appUrl,
      'return': appUrl
    }
  };

  const customization = {
    paymentMethods: {
      bankTransfer: 'all',
      creditCard: 'all',
      debitCard: 'all',
    },
    backUrls: {
      'error': appUrl,
      'return': appUrl
    }
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    formData.ordem_id = ordem_id;
  
    try {
      const response = await GenPost('api/process_payment', JSON.stringify(formData));
      setordem_mp(response.id);
      
      return response;
    } catch (error) {
      
      throw error; 
    }
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick

  };

  const onReady = async () => {
  setIsLoading(false)
  };


if (isLoading)
  return (
    <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
      <CircularProgress color="success" />
    </div>)
  
  else 
  
  return (<>
          {!StatusInit ? (
            <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
          />
        ) : null}

        {StatusInit ? (
          <StatusScreen
            customization={statusCustomization}
            initialization={StatusInit}
            onReady={onReady}
            onError={onError}
          />
        ) : null}
  </>
  )

}








