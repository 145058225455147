import React, { useEffect, useState } from 'react';
import { GenPost, GenGet } from 'Admin/state/api';
import PainelCaixas from './PainelCaixas';
import Toast from 'Admin/components/Toast';
import { CircularProgress } from '@mui/material';

function DeterminadaRegiao(){
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState(false);
  const [atualizarLoading, setAtualizarLoading] = useState(false);
  const [removerLoading, setRemoverLoading] = useState({item : 0, state:false});
  const [refresh, setRefresh] = useState(false);
  const [tipo, setTipo] = useState('Por Cidade');
  const [data, setData]=useState([]);

  const [formData, setFormData]= useState({
    'tipo' : 'regiao',
    'cep' : '',
    'cidade':'',
    'estado':'',
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTipo = (e) => {
    setTipo(e.target.value);
    setFormData({
      ...formData,
      'cep' : '',
      'cidade': '',
      'estado': '',
    })
  };

  const handlePost = () => {
    async function postData() {
      setAtualizarLoading(true)
      try {
        const response = await GenPost('/frete/novo-fretesisento', formData);
        setRefresh(true);
        setMsg(response.message)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        console.log(response)
        setAtualizarLoading(false)

      } catch (err) {
        setMsg(err.message)
        setAtualizarLoading(false)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    postData();
  };

  const handleDelete = (id) => {
    async function postData() {
      setRemoverLoading({
        item : id, 
        state:true})
      try {
        
        const response = await GenPost(`/frete/remover-freteisento/${id}`);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setRefresh(true);
        setRemoverLoading({
          item : 0, 
          state:false})

      } catch (err) {
        setMsg(err.message);
        setRemoverLoading({
          item : 0, 
          state:false})
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    postData();
  };

  async function getRegioesIsentas() {
    try {
      const response = await GenGet('/frete/fretes-isentos/regiao');
      // Ensure frete_isento is a boolean
      setData(response);
      setLoading(false);
      setRefresh(false);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getRegioesIsentas();
  }, []);
  
  useEffect(() => {
    if (refresh){
      getRegioesIsentas();
    }
    
  }, [refresh]);

if (loading){
  return(
    <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
      <CircularProgress color="success"/>
    </div>
  )
  } else {
    return(
      <div className='flex flex-col w-full bg-slate-50 text-[10pt]'>
        <h3 className='text-[12pt]'>Determinada região</h3>
        <div className='rounded-md flex flex-row gap-2'>
        <div className='flex flex-col justify-between'>
              <select
                className='px-4 w-[200px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='integracao_frete'
                onChange={handleTipo}
                placeholder='Tipo'
                id='tipo'
                name='tipo'
                value={tipo}
              >
                <option value='Por Cep'>Por Cep</option>
                <option value='Por Cidade'>Por Cidade</option>
                <option value='Por Estado'>Por Estado</option>
              </select>
        </div>
        {tipo === 'Por Cep' && (
        <div  className='flex flex-col gap-1'>
          <input 
            type="number"
            name="cep"
            onChange={handleChange}
            id="cep"
            placeholder='CEP'
            className="px-4 w-[200px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
        </div>
        )}
        {tipo === 'Por Cidade' && (
          <div  className='flex flex-col gap-1'>
            <input 
              type="text"
              name="cidade"
              onChange={handleChange}
              id="cidade"
              placeholder='Cidade'
              className="px-4 w-[200px] py-2 rounded border border-gray-300 focus:outline-none"/>
          </div>
        )}

        {tipo === 'Por Estado' && (
          <div  className='flex flex-col gap-1'>
            <input 
              type="text"
              name="estado"
              onChange={handleChange}
              id="estado"
              placeholder='Estado'
              className="px-4 w-[200px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
          </div>
        )}
        {msg ? 
          <Toast msg={msg} type='success'/>
        : null}         
        </div>
        <div className='w-full flex flex-row-reverse'>
        <button className='admin-button bg-blue-500 hover:bg-blue-400' onClick={handlePost}> {atualizarLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Cadastrar')}</button>
        </div>
        <div className='flex flex-col max-h-[300px] p-2 mt-4'>
        <h3 className='input-classes text-sm'>Regiões cadastradas</h3>
        {!loading && data && data.map((item, index) =>(
          <div className='w-full border-1 p-1 rounded-md m-1 border-slate-700 h-[50px] flex flex-row align-middle items-center justify-between' key={index}>
            <p className='text-[10pt] font-semibold'>Cep: <span className='font-medium'>{item.cep === null ? '-' : item.cep}</span></p>
            <p className='text-[10pt] font-semibold'>Cidade: <span className='font-medium'>{item.cidade === null ? '-' : item.cidade}</span></p>
            <p className='text-[10pt] font-semibold'>Estado: <span className='font-medium'>{item.estado === null ? '-' : item.estado}</span></p>
            <button className='admin-button bg-red-500 hover:bg-red-400' onClick={()=>handleDelete(item.id)}>{removerLoading.state === true && removerLoading.item === item.id ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : (<p className='text-[11pt] text-white m-0 p-0'>Remover</p>)}</button>    
          </div>
          ))}
        </div>
      </div>
    );
  }
}

function DeterminadoValor(){
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [atualizarLoading, setAtualizarLoading] = useState(false);
  const [removerLoading, setRemoverLoading] = useState(false);
  const [data, setData]=useState([]);
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    'tipo' : 'valor',
    'valor' : '',
  });

  const [validationErrors, setValidationErrors] = useState({
    valor: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? '' : 'Campo obrigatório.',
    });
  };

  const handlePost = () => {
    async function postData() {
      setAtualizarLoading(true)

      try {
        const response = await GenPost('/frete/novo-fretesisento', formData);
        setMsg(response.message)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setRefresh(true);
        setAtualizarLoading(false)

      } catch (err) {
        setMsg(err.message)
        setAtualizarLoading(false)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    postData();
  };

  const handleDelete = (id) => {
    async function postData() {
      setRemoverLoading({
        item : id, 
        state:true})

      try {
        const response = await GenPost(`/frete/remover-freteisento/${id}`);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setRefresh(true);
        setRemoverLoading({
          item : 0, 
          state:false})

      } catch (err) {
        setMsg(err.message);
        setRemoverLoading({
          item : 0, 
          state:false})
        setTimeout(() => {
          setMsg(null);
        }, 3000);

      }
    }
    postData();
  };

  async function getValoresIsentos() {

    try {
      const response = await GenGet(`/frete/fretes-isentos/valor`);
      setData(response);
      setLoading(false);
      setRefresh(false);

    } catch (err) {

    }
  }

  useEffect(() => {
    getValoresIsentos();
  }, []);
  
  useEffect(() => {
    if (refresh){
      getValoresIsentos();
    }
    
  }, [refresh]);

if(loading){
  return(    
    <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
      <CircularProgress color="success"/>
    </div>)
  } else {
    return(
      <div className='rounded-md flex flex-col w-full bg-slate-50 my-4 text-[10pt]'>
        <h3 className='text-[12pt]'>Determinado valor</h3>
        <div className=' flex flex-row gap-2 p-2'>
        <p className='text-[12pt] font-semibild pt-2'>Acima de:</p>
        <div  className='flex flex-col gap-1'>
          <input 
            type="number"
            name="valor"
            onChange={handleChange}
            id="valor"
            placeholder='Valor'
            className="px-4 w-[200px] h-[40px] py-2 rounded border border-gray-300 focus:outline-none"/>
        </div>
        
        </div>
        <div className='w-full flex flex-row-reverse'>
        <button className='admin-button bg-blue-500 hover:bg-blue-400' onClick={handlePost}> {atualizarLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Cadastrar')}</button>
        </div>
        {msg ? 
          <Toast msg={msg} type='info'/>
        : null}         

        <div className='flex flex-col max-h-[300px] p-2 mt-4'>
        <h3 className='input-classes text-sm'>Valores cadastrados</h3>
        {!loading && data && data.map((item, index) =>(
          <div className='w-full border-1 p-1 rounded-md border-slate-700 h-[50px] flex flex-row m-1 align-middle items-center justify-between' key={index}>
            <p className='text-[10pt] font-semibold'>Acima de: <span className='font-medium'>R${item.valor}</span></p>
            <button className='admin-button bg-red-500 hover:bg-red-400' onClick={()=>handleDelete(item.id)}>{removerLoading.state === true && removerLoading.item === item.id ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : (<p className='text-[11pt] text-white m-0 p-0'>Remover</p>)}</button>    
          </div>
          ))}
        </div>
      </div>
    );
  }
}

function PainelFrete() {
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [msg, setMsg] = useState(null);
  const [atualizarLoading, setAtualizarLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [formData, setFormData] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: false,
    frete_isento_tipo: 'regiao',
  });

  const [validationErrors, setValidationErrors] = useState({
    integracao_frete: 'melhorenvio',
    frete_isento: false,
    frete_isento_tipo: 'regiao',
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: value ? '' : 'Campo obrigatório.',
    });
  };

  const handlePost = async () => {
    try {
      setAtualizarLoading(true)
      const response = await GenPost('/params/frete', formData);
       
      setRefresh(true);
      setAtualizarLoading(false) 
      setMsg(response.message);
    
    } catch (err) {
      setAtualizarLoading(false)
      setMsg(err.message);
    
    } finally {
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GenGet('params/fretes');
        const { frete_isento, ...rest } = response;
  
        // Update formData with boolean conversion
        const updatedFormData = {
          ...rest,
          frete_isento: !!frete_isento,
        };
  
        setFormData(updatedFormData);
  
        // Update data state
        setData({
          ...rest,
          frete_isento: !!frete_isento,
        });
  
        setLoading(false);
      } catch (err) {
        console.error('Error fetching fretes:', err);
      }
    };
  
    fetchData();
  }, [refresh]);
  
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

if (loading){
  return (
    <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
      <CircularProgress color="success"/>
    </div>)
  } else {

    return (
      <div className='rounded-md flex flex-col w-full bg-slate-50 text-[10pt]'>
      <h3 className='text-[14pt]'>Configurações de frete</h3>
      <p className='text-[9pt] -mt-2'>configure o frete de sua loja.</p>
        <div className='p-2'>
          <div className='flex flex-row text-left overflow-hidden gap-2'>
            <div className='flex flex-row justify-start gap-2 mr-4'>
              <label className='font-semibold'>Frete Isento:</label>
              <input
                className='bg-slate-300 rounded h-[30px] flex p-1.5'
                id='frete_isento'
                name='frete_isento'
                type='checkbox'
                checked={formData.frete_isento}
                onChange={handleChange}
              />
            </div>
            <div className='flex flex-col justify-between'>
              <label>Tipo Isenção:</label>
              <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='frete_isento_tipo'
                onChange={handleChange}
                id='frete_isento_tipo'
                name='frete_isento_tipo'
                value={formData.frete_isento_tipo}
              >
                <option value='regiao'>Região</option>
                <option value='regiao_retirada'>Região + (retirada)</option>
                <option value='valor'>Acima de um valor</option>
                <option value='valor_retirada'>Acima de um valor + (retirada)</option>
                <option value='regiao_e_valor'>Região e Valor</option>
                <option value='regiao_ou_valor'>Região ou Valor</option>
                <option value='regiao_e_valor_retirada'>Região e Valor + (retirada)</option>
                <option value='regiao_ou_valor_retirada'>Região ou Valor + (retirada)</option>
              </select>
            </div>
            <div className='flex flex-col justify-between'>
              <label>Integração:</label>
              <select
                className='px-4 h-[40px] py-2 rounded border border-gray-300 focus:outline-none'
                aria-label='integracao_frete'
                onChange={handleChange}
                id='integracao_frete'
                name='integracao_frete'
                value={formData.integracao_frete}
              >
                <option value='melhorenvio'>Melhor Envio</option>
              </select>
            </div>
          </div>
          <div className='w-full flex flex-row-reverse'>
            <button
                className='bg-blue-500 hover:bg-blue-400 admin-button'
                onClick={handlePost}
              >
                {atualizarLoading ? (<CircularProgress color="success" size={22} style={{padding:2}}/>) : ('Atualizar')}
              </button>
            </div>
        </div>
        {msg ? 
              <Toast msg={msg} type='success'/>
            : null}         

        <hr/>
        <br />
        {data.frete_isento && (data.frete_isento_tipo === 'regiao' || data.frete_isento_tipo === 'regiao_retirada' || data.frete_isento_tipo === 'regiao_e_valor'|| data.frete_isento_tipo === 'regiao_ou_valor' || data.frete_isento_tipo === 'regiao_e_valor_retirada' || data.frete_isento_tipo === 'regiao_ou_valor_retirada') && (
          <DeterminadaRegiao />
        )}
        <hr/>
        {data.frete_isento && (data.frete_isento_tipo === 'valor' || data.frete_isento_tipo === 'valor_retirada' || data.frete_isento_tipo === 'regiao_e_valor'|| data.frete_isento_tipo === 'regiao_ou_valor' || data.frete_isento_tipo === 'regiao_e_valor_retirada' || data.frete_isento_tipo === 'regiao_ou_valor_retirada') && (
          <DeterminadoValor />
        )}
        <hr/>
        <PainelCaixas />
      </div>
    );
  }
}

export default PainelFrete;