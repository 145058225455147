import React, {useState, useEffect} from 'react'
import { GenGet } from 'Loja/state/api';
import Destaque from './Destaque';
import {Box, CircularProgress} from "@mui/material";

function ProdutosRelacionados({produto_id}) {
    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        if(produto_id){
            async function getRelacionados() {
                try {
                  const response = await GenGet(`api/relacionados/${produto_id}`);
                  setData(response);
                  setisLoading(false);
                } catch (err) {
                }
              }
              getRelacionados();
        } 
      }, [produto_id]);
    
    if (isLoading) {
        return (
        <Box sx={{ display: 'flex', width:'100%', height:400, justifyContent:'center' }}>
        <CircularProgress color="success" sx={{marginTop:'150px'}} />
        </Box>);}
    else {
    return (
        <div className='flex flex-col p-4 w-[100%]'>
            <h3 className='font-semibold text-[14pt] text-slate-700'>Produtos Relacionados</h3>
            <div className='flex  flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2 '>
            {data?  data.map(({ slug, nome, imagens, descricao }) => (
                    <Destaque
                        nome={nome}
                        slug={slug}
                        imagem={imagens.find(img => img.capa)?.imagem}
                        descricao={descricao}
                    />
                )) : (

                <CircularProgress color="success" sx={{margin:'10%'}}/>)}
            </div>
        </div>
    )}
}

export default ProdutosRelacionados