import axios from "Loja/state/axios";

const LOGOUT_URL = '/api/logout';

const useLogout = () => {
    
    async function getCsrf(){
      await axios.get('/sanctum/csrf-cookie');
      }

    const token = localStorage.getItem('token');
    
    const logout = async () => {     
        try{
            await axios({
            url: LOGOUT_URL,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${token}`},
            withCredentials:true,     
            });  
            localStorage.removeItem('token');
            localStorage.removeItem('cart');
            window.location.reload();

        }catch (err){
            localStorage.removeItem('token');
            localStorage.removeItem('cart');
            window.location.reload();

        }
    }
    return logout;
}
export default useLogout;