import React, { useState } from 'react';
import { GenPost } from 'Admin/state/api';

function NovoLead() {
  const [formData, setFormaData] = useState({email:''})
  const [msg, setMsg] = useState(null);
  const handleEmail = (e) =>{
    setFormaData({
      [e.target.name]:e.target.value
    })
  }
  const handlePost = async () => {
    if (formData.email === '') {
        setMsg("O campo 'Email' é obrigatório ");
        setTimeout(() => {
            setMsg(null);
        }, 3000);
        return;
    }

  try {

    const response = await GenPost('/leads', formData);
    setMsg(response.message);
    setFormaData({email:''});

  } catch (err) {
    setMsg(err.message);
  } finally {
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  }
};
  return (
    <div className='flex flex-col w-full min-w-[500px] p-2'>
    <h3 className='text-[14pt]'>Novo Lead</h3>
    <p className='text-[9pt] -mt-2'>Cadastre um novo lead. </p>
    <br/>
    <div className='flex flex-row gap-2 my-2 w-full'>
          <div className='flex flex-col gap-1 w-full'>
                <p className='p-0 m-0 font-semibold'>Email</p>
                  <input
                    name="email"
                    onChange={handleEmail}
                    id="email"
                    rows="4"
                    value={formData.email}
                    placeholder='e-mail'
                    className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
    </div>
    <input  />
    <div className='w-full flex flex-row-reverse'>
    <button className=' bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
    {msg && (
      <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
        <p className='m-0 p-0 text-gray-800'>{msg}</p>
      </div>
    )}
    </div>
    </div>
  )
}

export default NovoLead