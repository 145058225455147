import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function SubmitButton({ isLoading, onClick, children }) {
  return (
    <button
      className="w-[120px] h-[32px] p-2 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-sm shadow-sm flex justify-center align-middle items-center"
      onClick={!isLoading ? onClick : undefined}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress color="success" size={22} style={{ padding: 2 }} />
      ) : (
        <p className="text-[11pt] p-0 m-0">{children}</p>
      )}
    </button>
  );
}

export default SubmitButton;
