import React , { useState, useEffect } from "react";
import { GenGet, GenPost } from "Admin/state/api";
import { Alert, CircularProgress, FormControlLabel, Switch } from "@mui/material";
import CustomSelect from "Admin/components/CustomSelect";
import EstoqueMov from "./Index";
import MovimentoEstoqueSimples from "./MovimentoEstoqueSimples";
import MovimentoEstoqueComposto from "./MovimentoEstoqueComposto";
import GenericDataGrid from "Admin/components/DataGrids/GenericDataGrid";

function PainelProdutoEstoque() {
    const [produtosList, setProdutosList] = useState([]);
    const [produto, setProduto] = useState(null);
    const [produtoData, setProdutoData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [produtoRefresh, setProdutoRefresh] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ativos, setAtivos] = useState(true);

    const [produtoLoading, setProdutoLoading] = useState(true);

    useEffect(() => {
        async function getData() {
          try {
            const response = await GenGet("/produtos-list");
            const GridResponse = await GenGet(`/grid/produto`);
            setGridData(GridResponse);
            setProdutosList(response);
            setLoading(false)
            setRefresh(false);
          } catch (err) {
          }
        }
        getData();
      }, [refresh]);

      useEffect(() => {
        if(produto){
          async function getProduto() {
            try {
              const response = await GenGet(`produto/${produto.produto_id}`);
              setProdutoData(response);
              setProdutoRefresh(false);
              setProdutoLoading(false)
            } catch (err) {
            }
          }
          getProduto();
        }

      }, [produto, produtoRefresh]);

      const handleProduto = (id) =>{
        setProduto({
            ...produto,
            produto_id: id,
          });
      }

      const handlePointer = (id) =>{
        const produto = gridData.disponiveis.find(item => item.id === id);
        if (produto) {
          setProduto({
            ...produto,
            produto_id: produto.id,
          });
        } else console.log(id)
      }

      const handleSwitchChange = (event) => {
        setAtivos(event.target.checked);
      };

      const cols =['ID', 'Nome', 'Categoría'];
      const actions = [{ action: 'Visualizar', function: handlePointer }];

  return (
    <div className="flex flex-col min-h-[100%] w-full text-[10pt] no-scrollbar">
        <h3 className='text-[14pt]'>Painel de Estoque de Produto</h3>
        <p className='text-[9pt] -mt-2'>painel derivado para estoque de produtos</p>
        <FormControlLabel
                    control={<Switch checked={ativos} onChange={handleSwitchChange} />}
                    label={!ativos ? 'Mostrar Ativos' : 'Mostrar inativos'}
                />
        {!loading ? (<>{ativos ? (<GenericDataGrid rows={gridData.disponiveis} columns={cols} per_page={10} actions={actions} title="Produtos Ativos"/>) : (<GenericDataGrid rows={gridData.indisponiveis} columns={cols} per_page={10} actions={actions} title="Produtos Inativos"/>)}</>) : 
        (      
          <div className="flex justify-center align-middle items-center h-[300px]" display={'flex'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}>
            <CircularProgress color="success"/>
          </div>)}
        {produto && produto.produto_id && produtoData && (
            produtoData.estoque_composto === 0 || produtoData.estoque_composto === false ? (
                <MovimentoEstoqueSimples data={produtoData} handleRefresh={setProdutoRefresh}/>
            ) : (
                <MovimentoEstoqueComposto data={produtoData} handleRefresh={setProdutoRefresh}/>
            )
        )}
    </div>
  )
}

export default PainelProdutoEstoque