import React,  {useContext, useState} from "react";
import { CartContext } from "Ecommerce";
import ModalCalcFreteFinal from "Loja/components/compLoja/ModalCalcFreteFinal";

const ControladorFrete = ({cliente, CEP, data, setData, validate, validaFrete, isento, setIsento}) =>{
    const [ frete, setFrete ] = useState(null);
    const { frete_infos } = useContext(CartContext);
    
    const handleFrete = (frete) => {
      setFrete(frete.price); 
      validaFrete({
        ...validate,
        frete:true
      });
      setData({
        ...data, 
        frete_valor: frete.price,
        frete:frete,
        frete_infos: frete_infos
      })
    } 
  
    return(
  
    <div className="p-2">
      {isento ? (<p>Frete Gratis!</p>):(<ModalCalcFreteFinal handleFrete={handleFrete} cliente={cliente}  cep={CEP} data={frete_infos} setFrete={setFrete} frete={frete} isento={isento} setIsento={setIsento}/>)}
      
    </div>)
  }

  export default ControladorFrete;