import {useEffect, useState} from 'react';
import { Box, Typography} from "@mui/material";
import axios from "Admin/state/axios";

const token = localStorage.getItem('token');

function Usuarios() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getClientes() {
      try {
        const response = await axios({
          url: "users",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setData(response.data);
        setIsLoading(false);
      } catch (err) {

      }
    }
    getClientes();
  }, []);

  return (
    <Box sx={{padding:{md:'5%', xs:"10%"}}} display='flex' flexDirection={{md:'row', xs:'column'}} gap={2} flexWrap='wrap' alignContent={{xs:'center', md:'left'}}>
    {data.length > 0 ? (
            <Box
              mt="20px"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              sx={{
                "& > div": "span 4" ,
              }}
            >
              
              {data.map(({id,name,email,role,email_verified_at}) => (
                <Box>
                  <Typography>{id}</Typography>
                  <Typography>{name}</Typography>
                  <Typography>{email}</Typography>
                  <Typography>{role}</Typography>
                  <Typography>{email_verified_at}</Typography>
                  
                  </Box>
                ))}
            </Box>
          ) : (
            <>Carregando...</>
          )}

  </Box>
  );
}

export default Usuarios;