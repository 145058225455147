import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Typography , Stack} from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlaceIcon from '@mui/icons-material/Place';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import FreteRapido from '../../assets/frete.png';
import FreteBrasil from '../../assets/brasil.png';
import Pagamento from '../../assets/pagamento.png';
import Atendimento from '../../assets/atendimento.png';

const useStyles = makeStyles({
  icon: {
    "&:hover": {
      width: "60px",
      transition: "width 0.3s ease", // Adiciona uma transição suave
    },
  },
});

function Selos() {
    const classes = useStyles();
  return (
    <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-[#9e1717] font-semibold text-[11pt]'>Envio rápido</p>
      <LocalShippingIcon sx={{
            color: "#9e1717", // Cor do ícone
            fontSize: "40px", // Tamanho padrão do ícone
            transition: "all 0.3s ease",
            cursor:'pointer', // Suaviza as alterações de estilo
            "&:hover": {
              fontSize: "60px", // Novo tamanho no hover
            },
          }}/ >
     
        
      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-[#9e1717] font-semibold text-[11pt] text-wrap'>Frete para todo o Brasil</p>
      <PlaceIcon sx={{
            color: "#9e1717", // Cor do ícone
            fontSize: "40px", // Tamanho padrão do ícone
            transition: "all 0.3s ease",
            cursor:'pointer', // Suaviza as alterações de estilo
            "&:hover": {
              fontSize: "60px", // Novo tamanho no hover
            },
          }}/ >

      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-[#9e1717] font-semibold text-[11pt]'>Pagamento seguro</p>
      <CreditScoreIcon  sx={{
            color: "#9e1717", // Cor do ícone
            fontSize: "40px", // Tamanho padrão do ícone
            transition: "all 0.3s ease",
            cursor:'pointer', // Suaviza as alterações de estilo
            "&:hover": {
              fontSize: "60px", // Novo tamanho no hover
            },
          }}/ >

      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-[#9e1717] font-semibold text-[11pt]'>Excelente atendimento</p>
      <HeadsetMicIcon sx={{
            color: "#9e1717", // Cor do ícone
            fontSize: "40px", // Tamanho padrão do ícone
            transition: "all 0.3s ease",
            cursor:'pointer', // Suaviza as alterações de estilo
            "&:hover": {
              fontSize: "60px", // Novo tamanho no hover
            },
          }}/>

      </div>
    </Stack>
  )
}

export default Selos