import Toast from 'Admin/components/Toast';
import { GenPost } from 'Admin/state/api';
import React, { useState } from 'react';

function FinalizarVenda({ data, close }) {
  const [msg , setMsg] = useState(null);
  const [formData, setFormData] = useState({
    cliente_id : data.cliente_id,
    metodo_pagamento : 'dinheiro',
    itens : data.produtos,
    total : data.produtos.reduce((acc, produto) => acc + produto.valor_total, 0).toFixed(2),
    cupom : null

  })

  const handlePost = () => {
    console.log(formData)
    async function PostForm() {
        try {
          const response = await GenPost('vendaavulsa/executar', formData);
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
            close()
          }, 3000);
    
        } catch (err) {
    
        console.log(err)
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }

      PostForm();
  };

  const handleChange = (event) => {
    setFormData({
        ...formData,
        [event.target.name] : event.target.value});
  };

  return (
    <div className="mt-[20px] flex flex-col gap-3 relative">
      <h3 className="text-[14pt]">Finalizar Venda</h3>
      <hr />

      {/* Select para Método de Pagamento */}
      <div className='flex flex-row gap-2'>
        <div className="mb-4">
            <label htmlFor="metodo_pagamento" className="block text-sm font-semibold">
            Método de Pagamento
            </label>
            <select
            id="metodo_pagamento"
            name="metodo_pagamento"
            value={formData.metodo_pagamento}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
            >
            <option value="" disabled>
                Selecione um método de pagamento
            </option>
            <option value="dinheiro">Dinheiro</option>
            <option value="credito">Cartão de Crédito</option>
            <option value="debito">Cartão de Débito</option>
            <option value="pix">PIX</option>
            <option value="boleto">Boleto Bancário</option>
            </select>
        </div>
        
        <div className='flex flex-col'>
            <label htmlFor="cupom" className="block text-sm font-semibold">
            Cupom
            </label>
            <input
                id="cupom"
                name="cupom"
                value={formData.cupom}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded"
            />
            
        </div>
      </div>


      {/* Exibição do resumo do pedido, similar ao exemplo anterior */}
      <div>
        <h4 className="text-[12pt] font-bold mb-2">Resumo do Pedido</h4>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-2 py-1">Produto</th>
              <th className="border border-gray-300 px-2 py-1">Tipo</th>
              <th className="border border-gray-300 px-2 py-1">Quantidade</th>
              <th className="border border-gray-300 px-2 py-1">Valor Unitário</th>
              <th className="border border-gray-300 px-2 py-1">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {data.produtos.map((produto) => (
              <tr key={produto.id} className="text-center">
                <td className="border border-gray-300 px-2 py-1">{produto.nome}</td>
                <td className="border border-gray-300 px-2 py-1">{produto.tipo_nome}</td>
                <td className="border border-gray-300 px-2 py-1">{produto.quantidade}</td>
                <td className="border border-gray-300 px-2 py-1">
                  R${produto.valor.toFixed(2)}
                </td>
                <td className="border border-gray-300 px-2 py-1">
                  R${produto.valor_total.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="font-bold bg-gray-100">
              <td colSpan="4" className="border border-gray-300 px-2 py-1 text-right">
                Total Geral:
              </td>
              <td className="border border-gray-300 px-2 py-1 text-center">
                R${formData.total}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className='flex flex-row-reverse justify-between p-2'>
        <button className="bg-blue-400 hover:bg-blue-500 admin-button" onClick={handlePost}>Finalizar</button>
        {msg ? 
              <Toast msg={msg} type='info'/>
            : null}
      </div>
    </div>
  );
}

export default FinalizarVenda;
