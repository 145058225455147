import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NovoPublicoLeads() {
  const [data, setData] = useState({});
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  const [formData, setFormData] = useState({
    nome:'', 
    descricao:'',
    itens:{
      'avulso':[],
      'usuario':[]
    },
  })

  const handleField = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleItensAvulso = (data) => {
    setFormData({
      ...formData,
      itens: {
        ...formData.itens,
        'avulso': data,
      },
    });
  };
  
  const handleItensUsuario = (data) => {
    setFormData({
      ...formData,
      itens: {
        ...formData.itens,
        'usuario': data,
      },
    });
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await GenGet(`/leads/dashboard`);
            setData(response);
            setLoading(false);
            console.log(response)

        } catch (err) {
            console.log(err)
            setLoading(false);
        }
    };
  
    fetchData();
  }, []);

  const handlePost = async () => {
    console.log(formData)
    if (formData.nome === '' || formData.descricao === '') {
      setMsg("Os campos 'Nome' e 'Descrição' e pelo menos um lead é obrigatório.");
      setTimeout(() => {
          setMsg(null);
      }, 3000);
      return;
  }

    // Verifica se pelo menos um dos arrays em itens não está vazio
    if (formData.itens.avulso.length === 0 && formData.itens.usuario.length === 0) {
      setMsg("Pelo menos um lead deve ser selecionado.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

  try {

    const response = await GenPost('/leads/createlist', formData);
    setMsg(response.message);
    setFormData({
      nome:'', 
      descricao:'',
      itens:{
        'avulso':[],
        'usuario':[]
      },
    })

  } catch (err) {
    console.error('Error posting data:', err);
    setMsg(err.message);

  } finally {
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  }
  };
  const usuariosCols = ['ID', 'Nome','Email'];
  const avulsosCols = ['ID', 'Email'];

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Crie um Público</h3>
    <p className='text-[9pt] -mt-2'>crie um público ou uma lista personalizada.</p>
    <hr/>
    <div className='flex flex-row gap-2 my-2 w-full'>
          <div className='flex flex-col gap-1 w-full'>
                <p className='p-0 m-0 font-semibold'>Nome</p>
                  <input
                    name="nome"
                    onChange={handleField}
                    id="nome"
                    value={formData.nome}
                    placeholder='Nome'
                    className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
          <div className='flex flex-col gap-1 w-full'>
                <p className='p-0 m-0 font-semibold'>Descrição</p>
                <input
                    name="descricao"
                    onChange={handleField}
                    id="descricao"
                    value={formData.descricao}
                    placeholder='Descrição'
                    className="px-4 w-full py-2 rounded border border-gray-300 focus:outline-none"
                  />
          </div>
    </div>
    {!loading ? (
      <div className='flex w-full flex-col'>
        <GenericDataGrid rows={data.usuarios} columns={usuariosCols} per_page={25} title="usuários" handleSelect={handleItensUsuario} selectable={true}/>
        <GenericDataGrid rows={data.avulsos} columns={avulsosCols} per_page={25} title="avúlsos" handleSelect={handleItensAvulso} selectable={true}/>
      </div>) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      
      )}
      <div className='w-full flex flex-row-reverse gap-2'>
      <button className=' bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
      <button className=' bg-gray-400 hover:bg-gray-500 admin-button' onClick={()=>nav(`/admin/campanhas/leads`)}>Voltar</button>
      {msg && (
        <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
          <p className='m-0 p-0 text-gray-800'>{msg}</p>
        </div>
      )}
      </div>
    

    </div>
  )
}

export default NovoPublicoLeads

/* 



*/