import React,  {useState, useEffect} from "react";

const ControladorUser = ({handleuser, data, user, user_ok, validate, validauser}) =>{
    const [userFormData, setcliFormData] = useState({
          name: "",
          email: "",
          password:"",   
          password_confirmation: "",
      });
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setcliFormData({
          ...userFormData,
          [name]: value
        });
      
        // Verificar se todos os campos estão preenchidos
        const allFieldsFilled = Object.values(userFormData).every(field => field.trim() !== "");
        validauser({
          ...validate,
          user:allFieldsFilled
        });
        
      }
  
      useEffect(()=>{
        handleuser({
        ...data,
        user:userFormData
        })
        console.log({
          ...data,
          user:userFormData
          })
      },[userFormData])   
  
      useEffect(()=>{
        if(user_ok){
          validauser({
            ...validate,
            user:true
          });
        }
      },[user_ok])
      
    return(
    <div className="p-2">
      <h3 className='text-[13pt] text-slate-800 font-semibold'>
        Nova Conta
      </h3>
      {user_ok ? (
        <div>
          <p>Nome: {user.name}</p>
          <p className="m-0 p-0  text-[10pt]">Email: {user.email}</p>
        </div>
      ):(
          <div className='flex flex-col gap-2 mt-1'>
          <div className='flex flex-row gap-1 w-[100%] flex-wrap'>
            <input className={`min-w[130px] flex-grow p-1 h-[35px] bg-slate-300 rounded flex`}                 
              id="name"
              name="name"
              placeholder="Nome de Usuário"
              value={userFormData.nome} 
              onChange={handleChange}  
              />
            <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                 
              id="email"
              name="email"
              placeholder="Email"
              value={userFormData.email} 
              onChange={handleChange}  
              />
            </div>
            <div className='flex flex-row gap-1 w-[100%] flex-wrap'>
            <input className={`min-w[130px] flex-grow p-1 h-[35px] bg-slate-300 rounded flex`}                 
              id="password"
              name="password"
              type="password"
              placeholder="Senha"
              value={userFormData.password} 
              onChange={handleChange}  
              />
            <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                 
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              placeholder="Confirmar a senha"
              value={userFormData.password_confirmation} 
              onChange={handleChange}  
              />
            </div>
          </div> 
      )}
     
    </div>)
  }

  export default ControladorUser;