import React, { useState, useEffect } from 'react'
import CustomSelect from 'Admin/components/CustomSelect';
import { GenPost } from 'Admin/state/api';
import Toast from 'Admin/components/Toast';

const appUrl = process.env.REACT_APP_BASE_URL;

function MovimentoEstoqueSimples({data, handleRefresh}) {
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState('');
  useEffect(() => {
    setFormData({
      produto_id:data.id,
      quantidade:0
    })
  }, [data]);

  const [formData, setFormData] = useState({
    produto_id:data.id,
    quantidade:0
  });
  
  const handleQuantidade = (e) => {
    setFormData({
      ...formData,
      quantidade: e.target.value,
    });
  };

  const handleTipo = (id, value) =>{
    setFormData({
        ...formData,
        tipo: value,
      });
  }

  const handleSubmit = () => {
    if (!formData.tipo) {
      setMsg("Escolha um tipo de movimentação");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return; // Impede o envio do formulário
    }
  
    async function PostForm() {
      try {
        const response = await GenPost('mov-estoque', formData);
        setMsg(response.message);
  
        const novaQuantidade =
          formData.tipo === '1' // '1' representa 'entrada'
            ? data.estoque.quantidade + parseInt(formData.quantidade, 10)
            : data.estoque.quantidade - parseInt(formData.quantidade, 10);
  
        // Garante que a quantidade não seja negativa
        const quantidadeAjustada = Math.max(novaQuantidade, 0);
  
        // Atualiza o estoque no estado local
        handleRefresh({
          ...data,
          estoque: { ...data.estoque, quantidade: quantidadeAjustada },
        });
  
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
  };

  return (
    <div className='p-2 text-[10pt] min-h-[100%]'>
        <h3 className='text-[12pt]'>Estoque simples</h3>
        <hr/>
        <p className='font-semibold text-[11pt]'>{data.nome}</p>

        <div className='flex flex-row gap-2 items-center align-middle p-2 bg-slate-200 rounded-md'>
          <img src={`${appUrl}/${data.imagens.find(img => img.capa)?.imagem}`} className='w-[80px] h-[80px] rounded-md'/>
          <p className='text-[11pt] pl-4'>- Descrição: {data.descricao}</p>
          <p className='text-[11pt] pl-4 w-[200px] text-wrap'>- Status: {data.ativo === 1  || data.ativo === true ? 'Ativo' : "Inativo"}</p>
        </div>
        <div className='flex flex-col gap-2 p-2 bg-slate-200 rounded-md mt-2'>
          <p className='text-[11pt] pl-4'>Quantidade em estoque: {data.estoque.quantidade}</p>
          <p className='text-[11pt] font-medium pl-4'>  Movimentar estoque:</p>
          <div className='flex flex-row p-2 gap-2'>
          <CustomSelect data={{1:'entrada', 2:'saida'}} label={'Tipo'} onChange={handleTipo}/>
          <div  className='flex flex-col gap-1'>
          <input
            type="number"
            name="quantidade"
            id="quantidade"
            placeholder="Quantidade"
            value={formData.quantidade || 0}
            min="0"
            onChange={handleQuantidade}
            className="px-4 py-2 rounded border border-gray-300"
          />
          </div>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Confirmar</button>
          </div>
          {msg ? 
            <Toast msg={msg} type='success'/>
          : null}  
        </div>

        
    </div>
  )
}

export default MovimentoEstoqueSimples