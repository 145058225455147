import { GenGet } from 'Admin/state/api';
import React, {useEffect, useState} from 'react';
import MarketingProduto from './MarketingProduto'
import CustomSelect from 'Admin/components/CustomSelect';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';

function MarketingProdutos() {
    const [data, setData] = useState({});
    const [produtoId, setProdutoId ] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      async function getData() {
        try {
          const response = await GenGet(`/marketing/produtos`);
          setData(response);
          setIsLoading(false);
        } catch (err) {
          console.log(err)
        }
      }
      getData();
    }, []);

    const handleProduto = (id) =>{
        setProdutoId(id);
      }

      
    const columns = ['ID', 'Produto', 'Id Produto', 'Acessos'];
  
    const handleEdit = (id) => {
        const produto = data.acessos.find(item => item.id === id);
        if (produto) {
            setProdutoId(produto.produto_id);
        }
    };
    
    const actions = [{ action: 'Visualizar', function: handleEdit }];

  return (
    <div className='flex flex-col text-[10pt]'>
        <h3 className='text-[14pt]'>Painel de metricas de Produtos</h3>
        <p className='text-[9pt] -mt-2'>visualize as metricas dos seus produtos</p>
        {!isLoading && (
        <div className='flex flex-col w-full'>
        <GenericDataGrid rows={data.acessos} columns={columns} per_page={5} actions={actions} title="Acesso por produto" />
        <CustomSelect  data={data.produtos_list} label={'Selecione o Produto'} onChange={handleProduto} search={true}/>

        <hr/>
        {produtoId && (
          <MarketingProduto id={produtoId}/>
        )}
        </div>
        
        )}
        
    </div>
  )
}

export default MarketingProdutos