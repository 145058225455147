import React, { useState, useEffect } from 'react';
import { GenGet } from 'Admin/state/api';

function Avisos() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getAvisos() {
      try {
        const response = await GenGet('uavisos');
        console.log(response)
        setData(response);
        setIsLoading(false);
      } catch (err) {
        setData([]);
        setIsLoading(false);
      }
    }
    getAvisos();
  }, []);

  return (
    <div className="container mx-auto p-5">
      {!isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="bg-white shadow rounded p-4 h-[70vh] overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">Meus Avisos</h2>
            <hr className="mb-4" />
            {Array.isArray(data) && data.length > 0 ? (
              data.map(({ id, aviso, status }) => (
                <div key={id} className="bg-gray-100 my-2 p-2 rounded">
                  <p className="text-gray-800">{aviso} - {status}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">Nenhum aviso.</p>
            )}

          </div>

        </div>
      ) : (
        <div className="flex justify-center items-center h-[30vh]">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-green-500" role="status"></div>
        </div>
      )}
    </div>
  );
}

export default Avisos;
