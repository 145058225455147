import React from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ImgL from '../../assets/Moda_masc.png';
import ImgR from '../../assets/Moda_fem.png';


const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '400px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgBg: {
    backgroundImage: `url(${ImgR})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.8,
    transition: "transform 2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
    }
  },
  imgBgR: {
    backgroundImage: `url(${ImgL})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.8,
    transition: "transform 2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
    }
  },
  content: {
    position: "relative",
    zIndex: 1,
    alignItems:'center',
    textAlign: "center",
    color: "white",
    textShadow: '2px 2px 5px rgba(225, 225, 225, 0.7)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18pt',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '26pt',
    },
  },
  button: {
    marginTop: theme.spacing(1),
    padding:4,
    borderRadius:10,
    borderWidth:1,
    width:100,
    color:'white',
    textShadow: '2px 2px 5px rgba(225, 225, 225, 0.7)',
    fontWeight:500,
    borderColor:'white',
  },
  block: {
    flex: 1,
    height: '400px',
    margin: theme.spacing(2),
    backgroundColor: 'slategray',
    position: 'relative',
    cursor:'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(1, 0),
      height: 'auto',
    },
  },
  blockContent: {
    position: 'relative',
    height: '100%',
  }
}));

function DuasSecs() {
  const classes = useStyles();
  const navigate = useNavigate();

  const Sector = ({link, title, bgStyle}) =>{
    
    return(
      <div className={classes.block} onClick={() => {navigate(link)}}>
      <div className={classes.container}>
        <div className={bgStyle}></div>
        <div className={classes.content}>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className='flex flex-col md:flex-row justify-between flex-grow align-middle items-center w-[100%]'>
      <Sector link={'/produtos/Conjunto%20Verão%20Masculino'} title={'Moda Masculína'} bgStyle={classes.imgBgR}/>
      <Sector link={'/produtos/Conjunto%20Verão%20Feminino'} title={'Moda Feminína'} bgStyle={classes.imgBg}/>
    </div>

  );
}

export default DuasSecs;

