import axios from "axios";
const token = localStorage.getItem('token');
const GLOBAL_URL = process.env.REACT_APP_GLOBAL_URL;

export default axios.create({
    baseURL: `${GLOBAL_URL}`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`},
        withCredentials: true,    
});

export const api = axios.create({
  baseURL: GLOBAL_URL,
  headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem("token");
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
  },
  (error) => Promise.reject(error)
);


export const apiPixel = axios.create({
    baseURL: `${GLOBAL_URL}`,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });

