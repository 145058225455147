import React, { useEffect, useState } from "react";

function Toast({ msg, type, duration = 3000, delay = 500 }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Delay antes de iniciar a animação de entrada
    const showTimer = setTimeout(() => {
      setVisible(true);
    }, delay);

    // Ocultar após o tempo de exibição + delay
    const hideTimer = setTimeout(() => {
      setVisible(false);
    }, duration + delay);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [duration, delay]);

  const types = {
    success: "bg-green-500",
    error: "bg-red-500",
    warning: "bg-yellow-500",
    info: "bg-blue-500",
  };

  return (
    <div
      className={`fixed top-20 right-4 p-2 rounded-sm min-w-[200px] max-w-[400px] 
        shadow-md text-white text-sm font-semibold transition-all duration-300 ease-in-out
        ${visible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4 pointer-events-none"}
        ${types[type] || "bg-blue-500"}`}
    >
      {msg}
    </div>
  );
}

export default Toast;
