import React from 'react'

function Orcamentos() {
  return (
    <div className='mt-[20px] flex flex-col gap-1'>
    <h3 className='text-[14pt]'>Painel de Orçamentos</h3>
    <p className='text-[9pt] -mt-2'>visualize ou cadastre orçamentos.</p>
    <hr/>
        
    </div>
  )
}

export default Orcamentos