import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette:{
        primary:{
            main: "#1760a5",
            light: "skyblue",
        },
        secundary:{
            main: "#15c630"
        },
        corextra:{
            main:"#999"
        }
    }
})