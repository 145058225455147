import React, {useEffect} from 'react'
import { CssBaseline} from "@mui/material";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import EcommerceRoot from "./Ecommerce";
import AdminRoot from "./Admin";
import SellerRoot from './Seller';
import styled, { createGlobalStyle } from 'styled-components';
import RequiredAuth, { RequiredSellerAuth } from 'Loja/components/RequireAuth';


function App() {
  console.log(process.env.REACT_APP_GLOBAL_URL); 

  return (
    <div className="App">

        <BrowserRouter>
            <CssBaseline />
            <Routes>

              <Route path="/*" element={<EcommerceRoot />} />

              <Route element={<RequiredAuth/>}>       
                <Route path="/sistema/*" element={<AdminRoot appName='Beni Italy'/>} />
              </Route>

              <Route element={<RequiredSellerAuth/>}>       
                <Route path="/vendas/*" element={<SellerRoot appName='Beni Italy'/>} />
              </Route>
               
            </Routes>
           
        </BrowserRouter>

    </div>
  );
}

export default App;