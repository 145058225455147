import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Drawer,
  ListItem,
  List,
  ListItemText 
} from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Produto from 'Loja/components/compLoja/Produto';
import CloseIcon from '@mui/icons-material/Close';
import { GenGet } from 'Loja/state/api';
import ProdutoGridItem from 'Loja/components/compLoja/ProdutoGridItem';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';

const Categorias = ({ handleCategoryChange, handleFilterChange, handleFilterTipos, mode, setMode }) => {
  const [catdata, setCatData] = useState([]);
  const isSmScreen = useMediaQuery('(max-width:600px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filtroDinamico, setFiltroDinamico] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await GenGet("api/ucategorias");
        const searchOptions = await GenGet("api/ucategorias/search");
        if (searchOptions.length > 0) {
          setFiltroDinamico(searchOptions);
        }
        setCatData(response);
      } catch (err) {
        // Tratar erros, se necessário
      }
    }
    getCategorias();
  }, []);

  const handleButtonClick = (categoryId) => {
    handleCategoryChange({ target: { value: categoryId } });
    setIsDrawerOpen(false);
  };

  const handleGridMode = () => {
    setMode(mode === 'grid' ? 'list' : 'grid');
  };
  
  const cleanFilter = () =>{
    handleFilterTipos(null);
    handleButtonClick(0)
  } 

  const renderCategorias = () => (
    <div className="flex mb-2 flex-col gap-1">
      <h3 className='text-base font-semibold'>Categorías</h3>
      <div className={`cursor-pointer px-2 hover:bg-[#efac37] rounded-md`} onClick={cleanFilter}>
        <p className='p-0 m-0 text-[10pt]' onClick={()=>nav('/produtos')}>Todas</p>
      </div>
      {Array.isArray(catdata) && catdata.length > 0 &&
        catdata.map(item => (
          <div
            className={`cursor-pointer px-2 hover:bg-[#efac37] rounded-md`}
            key={item.id}
            onClick={() => nav(`/produtos/${item.nome}`)}
          >
            <p className='p-0 m-0 text-[10pt]'>{item.nome}</p>
          </div>
        ))
      }
      {filtroDinamico && filtroDinamico.map(filter => (
        <div key={filter.nome}>
          <hr />
          <h3 className='text-base font-semibold'>{filter.nome}</h3>
          <div className='pl-2 w-[100%]'>
            <p className='px-2 w-[100%] -mt-2 text-[10pt] hover:bg-[#efac37] rounded-md cursor-pointer' onClick={cleanFilter}>exibir tudo</p>
            <div className='w-full flex flex-row gap-1 flex-wrap -mt-2'>
              {filter.itens && filter.itens.map(item => (
                <div
                  key={item.id}
                  className='h-[30px] min=w-[60px] p-2 border-1 border-slate-400 hover:bg-slate-300 rounded-sm flex justify-center text-center cursor-pointer'
                  onClick={() => handleFilterTipos(item.id)}
                >
                  <p className='p-0 m-0 text-[9pt]'>{item.valor}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`p-2 ${!isSmScreen && 'max-w-[250px] sticky top-14'}`}>
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box sx={{ width: 250 }}>
          <div className='flex'>
            <input
              className='w-full p-2 bg-slate-100 rounded-md m-1'
              placeholder="Pesquisar"
              defaultValue=""
              variant="outlined"
              size="small"
              onChange={handleFilterChange}
            />
            <IconButton aria-label="delete" onClick={() => handleFilterChange({ target: { value: "" } })}>
              <SearchIcon />
            </IconButton>
          </div>
          {renderCategorias()}
        </Box>
      </Drawer>
      <br />
      {!isSmScreen ? (
        <>
       <div className='flex'>
          <input
            className='w-full p-2 bg-slate-100 rounded-md'
            placeholder="Pesquisar"
            defaultValue=""
            variant="outlined"
            size="small"
            onChange={handleFilterChange}
            />
          <IconButton aria-label="delete" onClick={() => handleFilterChange({ target: { value: "" } })}>
            <SearchIcon />
          </IconButton>
        </div>
        <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <h3 className='text-base font-semibold'>Categorías</h3>
        <div
          className={`cursor-pointer px-2 hover:hover:bg-[#efac37] rounded-md`} 
          onClick={cleanFilter}
        >
          <p className='p-0 m-0 text-[10pt]' onClick={()=>nav('/produtos')}>Todas</p>
        </div>
        {Array.isArray(catdata) && catdata.length > 0 &&
          catdata.map(item => (
            <div
              className={`cursor-pointer px-2 hover:bg-[#efac37] rounded-md`} 
              key={item.id}
              onClick={() => nav(`/produtos/${item.nome}`)}
            >
              <p className='p-0 m-0 text-[10pt]'>{item.nome}</p>
            </div>
          ))
        }
        {filtroDinamico && filtroDinamico.map(item =>(
        <div>
          <hr/>
            <h3 className='text-base font-semibold' >{item.nome}</h3>
              <div className='pl-2 w-[100%]'>
              <p className='px-2 w-[100%] -mt-2 text-[10pt] hover:bg-[#efac37] rounded-md cursor-pointer' onClick={cleanFilter}>exibir tudo</p>
                <div className='w-full flex flex-row gap-1 flex-wrap -mt-2'>
                {item.itens && item.itens.map(item =>(
                  <div className='h-[30px] min=w-[60px] p-2 border-1 border-slate-400 hover:bg-slate-300 rounded-sm flex justify-center text-center cursor-pointer' onClick={()=> handleFilterTipos(item.id)}>
                    <p className='p-0 m-0 text-[9pt]'>{item.valor}</p>
                  </div>
                ))}
                </div>

              </div>
        </div>
        ))}

      </Box>
      </>
      ) : (
        <div className='flex flex-row justify-between'>
          <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            {isDrawerOpen ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
          <div className='flex'>
            <input
              className='w-[250px] p-2 bg-slate-100 rounded-md'
              placeholder="Pesquisar"
              defaultValue=""
              variant="outlined"
              size="small"
              onChange={handleFilterChange}
            />
          </div>
          <div className='flex flex-row'>
            <IconButton onClick={handleGridMode}>
              {mode === 'grid' ? <FormatListBulletedIcon style={{ color: 'grey' }} /> : <WindowIcon style={{ color: 'grey' }} />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

function ProdutosCategoria() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cat, setCat] = useState(0);
  const [filter, setFilter] = useState('');
  const [gridMode, setMode] = useState('grid');
  const isSmScreen = useMediaQuery('(max-width:600px)');
  const [tiposFilter, setTiposFilter] = useState(null);

  const [notFound, setNotFound] = useState(false);
  const { categoria } = useParams();

  const handleCategoryChange = (event) => {
    const selectedCategory = parseInt(event.target.value);
    setCat(selectedCategory === 0 ? 0 : selectedCategory);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterTipos = (id) => {
    setTiposFilter(id);
  };

  useEffect(() => {
    async function getProdutos() {
      try {
        const response = await GenGet(`api/uprodutos-cat/${categoria}`);
        if (Array.isArray(response)) {
          setData(response);
          if (response.length === 0) {
            setNotFound(true);
          }
        } else {
          setData([]);
        }
        setIsLoading(false);
      } catch (err) {
        setNotFound(true);
      }
    }
    getProdutos();
  }, [categoria]);

  const filteredData = Array.isArray(data) ? data.filter(produto => {
    const categoriaValida = cat === 0 || produto.categoria_id === cat;
    const tipoValido = tiposFilter === null || (
      produto.estoque && 
      produto.estoque.unidade_estoque && 
      produto.estoque.unidade_estoque.some(unidade => 
        unidade.quantidade > 0 && 
        unidade.itens && 
        unidade.itens.some(item => item.valor.id === tiposFilter)
      )
    );
    return categoriaValida && tipoValido;
  }) : [];

  const filteredDataWithSearch = filter
    ? filteredData.filter(item =>
      item.nome.toLowerCase().includes(filter.toLowerCase()) ||
      item.descricao.toLowerCase().includes(filter.toLowerCase()))
    : filteredData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cat, tiposFilter]);

  if (notFound) {
    return navigate('/');
  } else {
    return (
      <div className={`flex ${!isSmScreen ? 'flex-row' : 'flex-col'} justify-center w-full min-h-[85vh]`}>
        <div className='sticky top-10 bg-[#f6e9d2] z-1'>
          <Categorias handleCategoryChange={handleCategoryChange} handleFilterChange={handleFilterChange} cat={cat} setMode={setMode} mode={gridMode} handleFilterTipos={handleFilterTipos} />
        </div>
        <div className='p-2 w-full min-h-[85vh]'>
          {!isLoading ? (
            <div className={`flex gap-2 w-full ${!isSmScreen ? 'justify-start flex-wrap' : 'flex-col justify-center'}`}>
              {!isSmScreen ? (
                <>
                  {filteredDataWithSearch.map(({ id, nome, slug, descricao, valor, imagens, categoria_id, peso, altura, largura, comprimento }) => (
                    <div className={'w-[90%] md:w-[30%] md:min-w-[250px] flex items-center justify-center'} key={id}>
                      <Produto
                        id={id}
                        imagem={imagens.find(img => img.capa)?.imagem}
                        nome={nome}
                        slug={slug}
                        descricao={descricao}
                        valor={valor}
                        categoria={categoria_id}
                        peso={peso}
                        altura={altura}
                        largura={largura}
                        comprimento={comprimento}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className='flex flex-row flex-wrap'>
                  {filteredDataWithSearch.map(({ id, nome, slug, descricao, valor, imagens, categoria_id, peso, altura, largura, comprimento }) => (
                    <div key={id}>
                      <ProdutoGridItem
                        id={id}
                        imagem={imagens.find(img => img.capa)?.imagem}
                        nome={nome}
                        slug={slug}
                        descricao={descricao}
                        valor={valor}
                        categoria={categoria_id}
                        peso={peso}
                        altura={altura}
                        largura={largura}
                        comprimento={comprimento}
                        tipo={gridMode}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
              <CircularProgress color="success" />
            </div>
          )}
        </div>
      </div>
    );
  }
}


export default ProdutosCategoria;


