import React, { useContext, useState, useEffect } from 'react';
import { EnvContext } from 'Ecommerce';
import { useNavigate } from 'react-router-dom';

export default function ProdutoGridItem({
  id,
  nome,
  slug,
  descricao,
  valor,
  peso,
  altura,
  imagem,
  largura,
  comprimento,
  tipo
}) {
  const [quantidade, setQuant] = useState(1);
  const produto = {
    'produto_id': id,
    'imagem': imagem,
    'nome': nome,
    'descricao': descricao,
    'valor': valor,
    'quantidade': quantidade,
    'quantity': quantidade,
    'weight': peso,
    'height': altura,
    'width': largura,
    'insurance_value': valor,
    'length': comprimento,
  };

  const { appUrl } = useContext(EnvContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };

  const truncatedDescription = descricao.length > 45
    ? descricao.substring(0, 45) + '...'
    : descricao;

    const truncatedNameHorizontal = descricao.length > 35
    ? nome.substring(0, 45) + '...'
    : nome;

  return (<>
  {tipo === 'grid' ? (
    <div className="flex flex-col mt-4 rounded-md shadow-md p-1 w-[46vw] h-[340px]">
    <div className="relative w-full overflow-hidden rounded-sm cursor-pointer" style={{ aspectRatio: '1 / 1' }}>
      {loading && (
        <div className="h-full overflow-hidden">
          <div className="min-h-full flex justify-center items-center align-middle bg-slate-300">
            <p className="font-semibold text-slate-700">Loading</p>
          </div>
        </div>
      )}
      <div className="relative w-full h-full">
        <img
          src={`${appUrl}/${imagem}`}
          alt={nome}
          onLoad={handleImageLoad}
          onClick={() => navigate(`/produto/${slug}`)}
          style={{
            display: loading ? 'none' : 'block',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          className="absolute"
        />
        <div className='absolute bottom-0 right-0 bg-slate-700 bg-opacity-60 w-full h-[30px]'>
          <span className="text-secondary mr-auto">
            <p className='text-slate-50 font-extrabold text-[12pt] text-end'>R${Number(valor).toFixed(2)}</p>
          </span>
        </div>
      </div>
    </div>

      <div className="mt-2 p-1 overflow-hidden h-[25px]">
        <span className="font-bold text-wrap text-[10pt] ]">{nome}</span>
      </div>
      <div className="p-1 h-[40px]">
        <span className="font-medium text-[9pt] block overflow-hidden overflow-ellipsis text-wrap">{truncatedDescription}</span>
      </div>
      <div className="flex justify-between w-full mt-auto">
        <button
          className="text-sm p-2 w-full bg-[#9e1717] hover:bg-[#00540b]  rounded-sm"
          onClick={() => navigate(`/produto/${slug}`)}
        >
          <p className='font-semibold p-0 m-0 text-[12pt] text-slate-50'>Comprar</p>
        </button>
      </div>
  </div>
  ) : (
    <div className="flex flex-row mt-4 rounded-md shadow-md p-2 w-[95vw] h-[150px]">
      <div className="w-[120px] h-[140px] rounded-sm cursor-pointer" style={{ aspectRatio: '1 / 1' }}>
        {loading && (
          <div className="h-full overflow-hidden">
            <div className="min-h-full flex justify-center items-center align-middle bg-slate-300">
              <p className="font-semibold text-slate-700">Loading</p>
            </div>
          </div>
        )}
        <div className="relative w-[120px] h-full">
          <img
            src={`${appUrl}/${imagem}`}
            alt={nome}
            onLoad={handleImageLoad}
            onClick={() => navigate(`/produto/${slug}`)}
            style={{
              display: loading ? 'none' : 'block',
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            className="absolute"
          />
          <div className='absolute bottom-0 right-0 bg-slate-700 bg-opacity-60 w-full h-[30px]'>
            <span className="text-secondary mr-auto">
              <p className='text-slate-50 font-extrabold text-[14pt] text-end'>R${Number(valor).toFixed(2)}</p>
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-grow flex-col h-[140px] ml-2'>
        <div className="mt-2 p-1">
          <span className="font-bold text-[9pt] h-[40px] text-wrap ">{truncatedNameHorizontal}</span>
        </div>
        <div className="p-1 h-[40px]">
          <span className="font-bold text-[8pt] block overflow-hidden overflow-ellipsis flex-owrap">{truncatedDescription}</span>
        </div>
        <div className="flex justify-between w-full mt-auto">
          <button
            className="text-sm p-2 w-full bg-[#9e1717] hover:bg-[#00540b]  rounded-sm"
            onClick={() => navigate(`/produto/${slug}`)}
          >
            <p className='font-semibold p-0 m-0 text-[12pt] text-slate-50'>Comprar</p>
          </button>
        </div>
      </div>
    </div>

  )}
  </>
  );
}