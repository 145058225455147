import axios from "Loja/state/axios";

async function UserAuth() {
  try {
    const res = await axios.get('api/usrauth');
    if (res.status === 200) {       
      return true;
      
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function AdminAuth() {
  try {
    const res = await axios.get('api/isadmin');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function SellerAuth() {
  try {
    const res = await axios.get('api/isseller');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function SellerCombinedAuth() {
  let usrLog = false;
  let slsLog = false;
  
  try {
    usrLog = await UserAuth();
    
    if (usrLog) {
      slsLog = await SellerAuth();
    }
    
    if (usrLog && slsLog) {
      return {user:true, sls:true};
    
    } else if (usrLog && !slsLog) {
      return {user:true, sls:false}
    
    } else {
      return {user:false, sls:false};
    }
  } catch (error) {

  }
}

async function CombinedAuth() {
  let usrLog = false;
  let admLog = false;
  
  try {
    usrLog = await UserAuth();
    
    if (usrLog) {
      admLog = await AdminAuth();
    }
    
    if (usrLog && admLog) {
      return {user:true, adm:true};
    
    } else if (usrLog && !admLog) {
      return {user:true, adm:false}
    
    } else {
      return {user:false, adm:false};
    }
  } catch (error) {

  }
}

async function UserIsAuth() {
  try {
    const usrLog = await UserAuth();
    return usrLog; 
  } catch (error) {

    return false;
  }
}

export { UserIsAuth, UserAuth, AdminAuth, CombinedAuth, SellerCombinedAuth };
