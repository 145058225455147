import React, {useEffect, useState}from 'react';
import Carrocel from '../../components/compLoja/Carrocel';
import DuasSecs from '../../components/compLoja/DuasSecs';
import Destaques from '../../components/compLoja/Destaques';
import Apresentacao from '../../components/compLoja/Apresentacao';
import Selos from '../../components/compLoja/Selos';
import { PixPost } from 'Loja/state/api';
import Ofertas from 'Loja/components/compLoja/Ofertas';




function LojaIndex() {
  const [postFeito, setPostFeito] = useState(false);

  useEffect(() => {
    async function countPx() {
      try {
        if (!postFeito) { // Verifica se o post já foi feito
          await PixPost('api/pixelhelper/HomePage', {});
          setPostFeito(true); // Marca que o post foi feito
        }
      } catch (err) {

      }
    }
    if (!postFeito) countPx();
  }, []);

  return (
    <div className='w-[100%]'>
    <br/>
    <Carrocel/>
    <br/>
    <Apresentacao/>
    <br/>
    <Destaques/>
    <br/>
    <hr/>
    <Selos/>
    <hr/>    
    </div>
  )
}

export default LojaIndex