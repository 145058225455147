import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Contato = () => {
  return (
    <div className={'flex flex-col p-4 sm:py-10 sm:px-20 justify-center align-middle items-center'}>
        <div className='flex flex-col border-1 border-yellow-600 rounded-md p-4 shadow-md max-w-[600px] text-[11pt]'>

        {/* Seção: Atendimento ao Cliente */}
        <div className="mb-6 ">
            <h3 className="text-xl font-bold mb-2">Atendimento ao Cliente</h3>
            <p className='px-4'>
            <span className='font-semibold'>Tem alguma dúvida ou precisa de suporte?</span> <br/>Entre em contato com nossa equipe de atendimento ao cliente.
            </p>

            {/* Informações de Contato */}
            <div className="flex items-center mb-2 px-8 gap-1">
            <EmailIcon color="primary" fontSize="small"/>
            <p className="text-base p-0 m-0">E-mail: atendimento@oticabeniitaly.com.br</p>
            </div>

            <div className="flex items-center px-8 gap-1">
            <WhatsAppIcon color="primary" fontSize="small" />
            <p className="text-base p-0 m-0">WhatsApp: (44)  8860-3030</p>
            </div>
            <p className="text-base p-4">Atendimento de Seg - Sex das 8:00 as 18:00</p>
        </div>

        {/* Seção: Revenda Nossa Marca */}
        <div>
            <h3 className="text-xl font-bold mb-2">Revenda Nossa Marca</h3>
            <p className='px-4'>
            <span className='font-semibold'>Está interessado em revender os produtos da Beni Italy em sua loja?</span> <br/>Entre em contato para discutir as oportunidades de parceria.
            </p>
            <div className='flex w-full p-4 justify-end'>
            <a
                href="mailto:revenda@oticabeniitaly.com.br"
                className="inline-block px-4 py-2 bg-blue-500 text-white rounded-md text-base font-semibold hover:bg-blue-600"
                >
                Tornar-se Revendedor
            </a>
            </div>

        </div>
    </div>
    </div>
  );
};

export default Contato;