import React , { useState, useContext } from "react";
import { CartContext } from "Ecommerce";
import { useNavigate } from "react-router-dom";
import { 
  Box,
  Typography,
  TextField,
  Button } from "@mui/material";

import { GenPostTemp } from "Loja/state/api";
import Toast from "Admin/components/Toast";

const NovoUsuario = ( props ) => {
  const { track } = useContext(CartContext);
  const [msg, setMsg] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true); 
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    password:"",   
    password_confirmation: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation:"",
  });

  const setName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      name: e.target.value ? "" : "Campo obrigatório.",
    });
  };
  const setEmail = (e) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      email: e.target.value ? "" : "Campo obrigatório.",
    });
  };
  const setPassword = (e) => {
    setFormData({
      ...formData,
      password: e.target.value,
    });
    setValidationErrors({
      ...validationErrors,
      password: e.target.value ? "" : "Campo obrigatório.",
    });
  };
  const setPassConf = (e) => {
    const passwordConfirmation = e.target.value;
    setFormData({
      ...formData,
      password_confirmation: passwordConfirmation,
    });
    setValidationErrors({
      ...validationErrors,
      password_confirmation: passwordConfirmation ? "" : "Campo obrigatório.",
    });
    setPasswordMatch(passwordConfirmation === formData.password);
  };

  const handleRes = (res) =>{
    navigate('/login')
    props.onSuccess(res);
    setTimeout(() => {
      setMsg(res.msg);
    }, 3000);  
  }

  const handleSubmit = (e) => {
      e.preventDefault();

      const validationErrors = {
        name: formData.name ? "" : "Campo obrigatório.",
        email: formData.email ? "" : "Campo obrigatório.",
        password: formData.password ? "" : "Campo obrigatório.",
        password_confirmation: formData.password_confirmation ? "" : "Campo obrigatório.",
      };
      if (formData.password_confirmation !== formData.password) {
        validationErrors.password_confirmation = "As senhas não correspondem.";
      }
      // Update validation errors state
      setValidationErrors(validationErrors);
    
      // Check if any validation errors exist
      if (Object.values(validationErrors).some((error) => !!error)) {
        return;
      }
      GenPostTemp("/api/register", formData, props.token)
      .then((response) => {
        handleRes(response);
        
        window.location.href = track;
      })
      .catch((error) => {

      });
    }
  
  return (
    <Box sx={{ p: 1, width: {md:"100%"}}} display="flex"
    justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p="0.25rem 1.25rem"
        borderRadius="0.55rem"
        sx={{ p: 1, m: 2, width:{lg:'40vw', md:'60vw', sm:'70vw', xs:'90vw'}}}
      >
      <Box sx={{p:0,m:1}} >
      <Typography variant="h5">Novo Usuário</Typography><hr/>    

          <Box sx={{p:0, marginTop:2, width:'100%'}}>
          <TextField  fullWidth 
                variant="standard"
                label="Nome :" 
                id="name" 
                name="name"  
                onChange={setName} 
                error={!!validationErrors.name}
                helperText={validationErrors.name}
                />
          </Box>
              

          <Box sx={{p:0, marginTop:2, width:'100%'}}>
            <TextField  fullWidth 
                variant="standard"
                label="Email :" 
                id="email" 
                name="email" 
                onChange={setEmail} 
                error={!!validationErrors.email}
                helperText={validationErrors.email}/>
          </Box>

          <Box sx={{p:0,marginTop:2, width:'100%'}}>
            <TextField  fullWidth 
                variant="standard"
                id="password" 
                label="Senha :"
                name="password" 
                type={"password"}
                onChange={setPassword}
                error={!!validationErrors.password}
                helperText={validationErrors.password} />
          </Box>

          <Box  sx={{p:0,marginTop:2, width:'100%'}}>
              <TextField  fullWidth 
                variant="standard"
                label="Confirmar Senha :" 
                id="password_confirmation" 
                name="password_confirmation" 
                type={"password"}
                onChange={setPassConf} 
                error={!!validationErrors.password_confirmation || !passwordMatch}
                helperText={
                  !passwordMatch
                    ? "As senhas não correspondem."
                    : validationErrors.password_confirmation
                }/>
          </Box>
          {msg ? 
              <Toast msg={msg.message} type='success'/>
            : null}            
          <Box  sx={{p:0,m:1, marginTop:2, width:'100%'}} display="flex" flexDirection={'row-reverse'} justifyContent="space-between">         
          <Button variant="contained" onClick={handleSubmit}>Cadastrar</Button>

          </Box>

    </Box>
  </Box>
  </Box>
  );
};
export default NovoUsuario;