import React from 'react';
import Img from '../../assets/LOGO-BENI-ITALY.png'
import { useNavigate } from 'react-router-dom';

const Sobre = () => {
  const nav = useNavigate()
  return (
    <div className={'flex flex-col  p-4 sm:py-10 sm:px-20 justify-center align-middle items-center'}>
        <div className='flex max-w-[600px] flex-col border-1 border-red-700 rounded-md p-4 shadow-md text-[11pt]'>
        <img src={Img} width={100} height={100} className='m-4'/>
        <p className='px-4'>
            <span className='font-semibold'>A Beni Italy é uma marca especializada em atacado de armações para óculos,</span> com foco em oferecer produtos de <span className='font-semibold'>alta qualidade, durabilidade e design único</span>. Cada modelo é pensado para ser a verdadeira grife dos olhos, unindo estilo e resistência em cada detalhe.
        </p>
        <p className='px-4'>
            Em nosso <span className='font-semibold'>e-commerce</span>, trabalhamos com uma <span className='font-semibold'>seleta linha de armações</span>, pensadas para atender as necessidades do mercado com o melhor custo-benefício. <span className='font-semibold'>Além disso, somos fornecedores</span> para lojas que desejam revender nossas peças, oferecendo produtos exclusivos que encantam pelo estilo e durabilidade.
        </p>
        <hr/>
        <p className='px-4'>
            <span className='font-semibold'>Atuamos em todo o Brasil</span>, garantindo entrega rápida e segura para todos os nossos clientes. <span className='font-semibold'>Nossa missão é proporcionar aos consumidores armações de óculos de excelente qualidade</span>, que aliam design sofisticado, resistência e custo acessível, ajudando a criar o estilo perfeito para cada olhar.
        </p>
        <hr/>
        <div className='p-2 flex flex-row justify-between'>
          <p className='font-semibold underline cursor-pointer' onClick={()=>(nav('/termos'))}>Termos de uso e cookies</p>
          <p className='font-semibold underline cursor-pointer' onClick={()=>(nav('/politica-devolucao'))}>Política de devoluções</p>
        </div>
        </div>

    </div>
  );
};

export default Sobre;