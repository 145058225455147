import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, Pagination } from '@mui/material';
import { GenGet } from 'Loja/state/api';
import Destaque from './Destaque';

function Destaques() {
  const [produtos, setProdutos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const produtosPorPagina = 3;

  useEffect(() => {
    async function getProdutos() {
      try {
        const response = await GenGet('api/destaques/home');
        setProdutos(response || []);
      } catch (err) {
        // Tratar erros, se necessário
        console.error("Erro ao carregar produtos", err);
        setProdutos([]);
      } finally {
        setIsLoading(false);
      }
    }
    getProdutos();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const produtosExibidos = produtos.slice(
    (page - 1) * produtosPorPagina,
    page * produtosPorPagina
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress color="success" />
      </Box>
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" paddingBottom="10px">
          <h3 className='text-[18pt] font-semibold italic text-[#9e1717]'>
            Veja Nossos Destaques!
          </h3>
          <hr className='w-[125%]'/>
        </Box>
        <div className='flex flex-col p-4 w-[100%]'>
          <div className='flex flex-col md:flex-row flex-wrap justify-center items-center align-middle gap-2'>
            {produtosExibidos.map((item) => (
              <Destaque
                key={item.produto.id}
                id={item.produto.id}
                nome={item.produto.nome}
                descricao={item.produto.descricao}
                slug={item.produto.slug}
                imagem={item.produto.imagens.find(img => img.capa)?.imagem}
              />
            ))}
          </div>
        </div>
        <Pagination
          count={Math.ceil(produtos.length / produtosPorPagina)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    );
  }
}

export default Destaques;
