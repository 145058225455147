import { GenGet } from 'Admin/state/api';
import React, {useEffect, useState} from 'react';
import MarketingUsuario from './MarketingUsuario'
import CustomSelect from 'Admin/components/CustomSelect';
import { CircularProgress } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';

function MarketingUsuarios() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    async function getData() {
      try {
        const response = await GenGet(`/marketing/usuarios`);
        console.log(response)
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.log(err)
      }
    }
    getData();
  }, []);

  const handleUser = (id) =>{
    setUserId(id);
  }
  
  const columns = ['ID', 'Página', 'Usuário','Id Usuário', 'Acessos'];
  
  const handleEdit = (id) => {
    const user = data.acessos.find(item => item.id === id);
    if (user) {
        setUserId(user.user_id);
    }
};
  
  const actions = [{ action: 'Visualizar', function: handleEdit }];

  return (
    <div className='flex flex-col w-full text-[10pt] p-2'>
        <h3 className='text-[14pt]'>Painel de metricas de Usuários</h3>
        <p className='text-[9pt] -mt-2'>visualize as metricas dos seus clientes</p>
        <hr/>
        {!isLoading ? (
          <div className='flex flex-col w-full p-4 gap-2'>
          <GenericDataGrid rows={data.acessos} columns={columns} per_page={5} actions={actions} title="Página por usuário" />     
          <CustomSelect  data={data.user_list} label={'Selecione o Usuário'} onChange={handleUser} search={true}/>
          <hr />
          {userId && (
            <MarketingUsuario id={userId}/>
          )}
          </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}

        
    </div>
  )
}

export default MarketingUsuarios