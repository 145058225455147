import React, { useEffect, useState } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import NovoLead from './NovoLead';

function PublicoLeads() {
    const { id } = useParams();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const [leadList, setLeadList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState(null);

    const filterLeads = (itens) => {
      return itens.map(item => {
          if (item.user) {
              return {
                  id: item.id,
                  email: item.user.email,
                  nome: item.user.name
              };
          } else if (item.lead) {
              return {
                  id: item.id,
                  email: item.lead.email,
                  nome: 'avulso'
              };
          }
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const removeLead = (id) => {
    const lead = leadList.find(item => item.id === id);
    handleDelete(lead.id);
  };
    const handleDelete = async (lead) => {
      try {
        const response = await GenPost(`/leads/deletelist/item/${lead}`);
        setMsg(response.message);
      } catch (err) {
        setMsg(err.message);
      } finally {
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    };
  const actions = [{ action: 'Remover', function: removeLead }];

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await GenGet(`/leads/showlist/${id}`);
              setData(response);
              setLeadList(filterLeads(response.itens))
              setLoading(false);
  
          } catch (err) {
              setLoading(false);
          }
      };
    
      fetchData();
    }, []);

    const cols = ['ID','Email', 'Nome'];

  return (
    <div className='flex flex-col w-full p-2'>
    <h3 className='text-[14pt]'>Seu Público</h3>
    <p className='text-[9pt] -mt-2'>detalhes do públicos / lista de leads.</p>
    <hr/>
    {loading ? (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
    ) : (
      <div className='flex flex-col relative'>
        <p className='p-0 m-0 text-[11pt] font-semibold'>{data.nome}</p>
        <p className='py-0 px-2 m-0 text-[9pt]'>{data.descricao}</p>
        <button className='absolute right-5 top-2 bg-blue-400 hover:bg-blue-500 admin-button' onClick={()=>nav(`/admin/campanhas/publico/addlead/${id}`)}>Novo Lead</button>
        <Dialog open={open} onClose={handleClose} style={{minWidth:'70vw'}}>
            <DialogContent>
              <NovoLead/>
            </DialogContent>
        </Dialog>
        <br/>
        <GenericDataGrid rows={leadList} columns={cols} actions={actions} per_page={15} title="leads"/>
      </div>
    )}
    </div>
  )
}

export default PublicoLeads