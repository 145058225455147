import React, {useState, useEffect, createContext} from 'react'
import { Route, Routes, Navigate } from "react-router-dom";

import SalesPannel from 'Admin/scenes/newPannel/SalesPannel';
import PedidosVendedor from 'Admin/scenes/painel-vendas/Pedidos';
import VendasVendedor from 'Admin/scenes/painel-vendas/Vendas';
import PainelVendaAvulsa from 'Admin/scenes/painel-vendas/PainelVendaAvulsa';
import Avisos from 'Admin/scenes/avisos/Index';


function SellerRoot() {

  return (
        <div>
          <Routes>
            <Route element={<SalesPannel />}>
              <Route exact path="/" element={<PainelVendaAvulsa />} />              
              <Route exact path="/ref" element={<Navigate to="/vendas" replace />} />  
              <Route path="/pedidos" element={<PedidosVendedor/>} />
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/*" element={<VendasVendedor/>} />
            </Route>
          </Routes>
    </div>
  );
}

export default SellerRoot;