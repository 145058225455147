import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GenPut, GenGet } from "Loja/state/api";
import { Box, CircularProgress } from "@mui/material";
import { GetOrdem, GetPagamento } from "Admin/state/api";

function PagamentoPendente({ id }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      async function getPedido() {
        try {
          await GetPagamento(id);
          const ordem = await GetOrdem(id);
          setData(ordem);
        } catch (err) {
          console.error("Erro ao buscar pedido:", err);
        } finally {
          setIsLoading(false);
        }
      }
      getPedido();
    }
  }, [id]);

  const handleSubmit = async (id) => {
    try {
      await GenPut(`api/ordem/finalizando/${id}`, { status: "enviando" });
      alert("Pedido atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar o pedido:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress color="success"/>
      </div>
    );
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="text-center text-red-500 text-lg font-bold">
        Nenhum dado encontrado para o pedido.
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg">
      <div className="w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Detalhes do Pedido</h2>
        <hr className="my-2" />
        <p className="text-lg">
          <strong>Nome do usuário:</strong> {data.user?.name}<br />
          <strong>E-mail:</strong> {data.user?.email}<br />
          <strong>CPF:</strong> {data.cliente?.cpf}<br />
          <strong>Telefone:</strong> {data.cliente?.telefone}<br />
          <br />
          <strong>Data:</strong> {data.detalhesOrdem?.created_at.slice(0, 10)}<br />
          <strong>ID:</strong> {data.detalhesOrdem?.id}<br />
          <strong>Valor:</strong> R$ {data.ordem?.valor_total}<br />
          <strong>Status:</strong> {data.ordem?.status}
        </p>
        <hr className="my-2" />
        <h3 className="text-xl font-semibold">Itens do Pedido</h3>
        <div>
          {data.itensOrdem?.map((item) => (
            <div key={item.id} className="mb-4 p-4 bg-white shadow rounded-lg">
              <p>Produto: {item.nome_produto}</p>
              <p>Quantidade: {item.quantidade}</p>
              <p>Preço Unitário: R$ {item.preco_unitario}</p>
              {item.estoque_unidade && (
                <p>
                  Tipo: {item.estoque_unidade.nome} - ID do Produto: {item.estoque_unidade.produto_id}
                </p>
              )}
            </div>
          ))}
        </div>
        <hr className="my-2" />
        <h3 className="text-xl font-semibold">Endereço</h3>
        {data.ordem?.retirada === 1 || data.ordem?.retirada === true ? (
          <p>Pedido a retirar na loja.</p>
        ) : data.detalhesOrdem?.end ? (
          <>
            <p>{data.detalhesOrdem.end.endereco} - {data.detalhesOrdem.end.complemento}</p>
            <p>{data.detalhesOrdem.end.cidade} - {data.detalhesOrdem.end.estado}</p>
            <p>{data.detalhesOrdem.end.cep} - {data.detalhesOrdem.end.codigo_pais}</p>
            {data.ordem.melhor_envio?.id_etiqueta ? (
              <p>Etiqueta Melhor Envio: <strong>{data.ordem.melhor_envio.id_etiqueta}</strong></p>
            ) : (
              <p className="text-red-500">Nenhuma etiqueta vinculada ao pedido, verificar o motivo.</p>
            )}
          </>
        ) : (
          <p className="text-red-500">Nenhum endereço vinculado ao pedido.</p>
        )}
        <hr className="my-2" />
        <h3 className="text-xl font-semibold">Informações Mercado Pago</h3>
        {data.pagamento ? (
          !data.pagamento.error ? (
            <>
              <p>ID MERCADO PAGO: {data.pagamento.id}</p>
              <p>TIPO: {data.pagamento.payment_method_id}</p>
              <p>Valor: R$ {data.pagamento.transaction_amount}</p>
              <p>Status do Pagamento: <strong>{data.pagamento.status}</strong></p>
            </>
          ) : (
            <p className="text-red-500">{data.pagamento.error}</p>
          )
        ) : (
          <p className="text-red-500">Nenhum pagamento vinculado ao pedido, verificar o motivo.</p>
        )}
      </div>
      <button
        onClick={() => handleSubmit(data.ordem?.id)}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
      >
        Fechar Pedido
      </button>
    </div>
  );
}

export default PagamentoPendente;
