import React,{ useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import WebIcon from '@mui/icons-material/Web';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import useLogout from "hooks/useLogout";
import {
    ShoppingCart as ShoppingCartIcon,
    Person as PersonIcon,
    Store as StoreIcon,
    NotificationsNone as NotificationIcon,
    AttachMoneyOutlined as MoneyIcon,

  } from "@mui/icons-material";

import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem 
} from "@material-ui/core";


const NewPannel = ({appName}) => {
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isSmScreen = useMediaQuery('(max-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [showProdutos, setShowProdutos] = useState(false);
    const [showClientes, setShowClientes] = useState(false);
    const [showIntegracoes, setShowIntegracoes] = useState(false);
    const [showCampanhas, setShowCampanhas] = useState(false);
    const [showUsabilidade, setShowUsabilidade] = useState(false);
    const [title, setTitle] = useState('');
    const logout = useLogout()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {

      }, [title]);

  return (
    <div className={'flex flex-row w-full h-[100vh] bg-[#1f1f1f] text-white'}>
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} style={{overflowY:'auto'}}  className={'no-scrollbar'}>
            <div className="h-screen overflow-y-auto md:flex flex-col min-w-[200px] bg-[#1f1f1f] no-scrollbar">
            <p className="text-white m-2 text-lg">Sistema - {appName}</p>
            <ul className="space-y-2">
                {/* Dashboard */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/sistema'); setTitle('Painel sistema'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <PieChartIcon />
                        Dashboard
                    </button>
                </li>
                
                {/* Vendedor */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/vendas'); setTitle('VENDEDOR'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <MoneyIcon />
                        Realizar Venda
                    </button>
                </li>

                {/* Vendas */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/sistema/vendas'); setTitle('Vendas'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <StoreIcon />
                        Vendas
                    </button>
                </li>

                {/* Avisos */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/sistema/avisos'); setTitle('Avisos'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <NotificationIcon />
                        Avisos
                    </button>
                </li>

                {/* Pedidos */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { navigate('/sistema/pedidos'); setTitle('Pedidos'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <LocalShippingIcon />
                        Pedidos
                    </button>
                </li>

                <hr className="border-gray-700"/>

                {/* Configurações */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => setShowSettings(!showSettings)}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <WebIcon />
                        Configurações
                        <ChevronRightIcon />
                    </button>
                    {/* Dropdown de Configurações */}
                    {showSettings && (
                        <ul className="pl-4 space-y-1">
                            <li >
                                <button 
                                    onClick={() => { navigate('/sistema/painel-loja'); setTitle('Gerais'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                                >
                                    Gerais
                                </button>
                            </li>
                            <li >
                                <button 
                                    onClick={() => { navigate('/sistema/carrossel'); setTitle('Carrossel'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                                >
                                    Carrossel
                                </button>
                            </li>
                            <li >
                                <button 
                                    onClick={() => { navigate('/sistema/frete'); setTitle('Frete'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                                >
                                    Frete
                                </button>
                            </li>
                            <li >
                                <button 
                                    onClick={() => { navigate('/sistema/especificacoes-estoque'); setTitle('Configuração de estoque'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                                >
                                    Configurar estoque
                                </button>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Produtos */}
                <li className="flex w-full flex-col">
                    <button 
                        onClick={() => { setShowProdutos(!showProdutos); setTitle('Produtos'); }}
                        className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <ShoppingCartIcon />
                        Produtos
                    </button>
                    {showProdutos && (
                    <ul className="pl-4 space-y-1">
                    <li >
                        <button 
                            onClick={() => { navigate('/sistema/produtos'); setTitle('Ver Produtos'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                        >
                            Ver Produtos
                        </button>
                    </li>
                    <li >
                        <button 
                            onClick={() => { navigate('/sistema/produto/novo'); setTitle('Novo Produto'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                        >
                            Novo Produto
                        </button>
                    </li>
                    <li >
                        <button 
                            onClick={() => { navigate('/sistema/categorias'); setTitle('Categorias'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                        >
                            Categorias
                        </button>
                    </li>
                    <li >
                        <button 
                            onClick={() => { navigate('/sistema/produtos/destaques'); setTitle('Destaques'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                        >
                            Destaques
                        </button>
                    </li>
                    <li >
                        <button 
                            onClick={() => { navigate('/sistema/estoque-produto'); setTitle('Painel de Estoque'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                        >
                            Painel de Estoque
                        </button>
                    </li>
                    </ul>
                    )}

                </li>

                {/* Clientes */}
                <li className="flex w-full flex-col">
                <button
                    onClick={() => { setShowClientes(!showClientes); setTitle('Clientes'); }}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <PersonIcon />
                    Clientes
                    <ChevronRightIcon style={{ transform: showClientes ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                </button>
                {showClientes && (
                    <ul className="pl-4 space-y-1">
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/usuario/novo'); setTitle('Novo Usuário'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Novo Usuário
                            </button>
                        </li>
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/clientes'); setTitle('Clientes'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Ver Clientes
                            </button>
                        </li>
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/clientes/orcamentos'); setTitle('Orçamentos'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Orçamentos
                            </button>
                        </li>
                    </ul>
                )}
                </li>

                {/* Integrações */}
                <li >
                    <button
                        onClick={() => { setShowIntegracoes(!showIntegracoes); setTitle('Integrações'); }}
                        className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <AccountTreeIcon />
                        Integrações
                        <ChevronRightIcon style={{ transform: showIntegracoes ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                    </button>
                    {showIntegracoes && (
                        <ul className="pl-4 space-y-1">
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/integracoes/mercado_pago'); setTitle('Mercado Pago'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Mercado Pago
                                </button>
                            </li>
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/integracoes/melhor_envio'); setTitle('Melhor Envio'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Melhor Envio
                                </button>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Campanhas */}
                <li className="flex w-full flex-col">
                    <button
                        onClick={() => { setShowCampanhas(!showCampanhas); setTitle('Campanhas'); }}
                        className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <SendIcon />
                        Campanhas
                        <ChevronRightIcon style={{ transform: showCampanhas ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                    </button>
                    {showCampanhas && (
                        <ul className="pl-4 space-y-1">
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/campanhas/cupoms'); setTitle('Cupons Promocionais'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Cupons de Desconto
                                </button>
                            </li>
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/campanhas/leads'); setTitle('Painel de Leads'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Painel de Leads
                                </button>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Usabilidade */}
                <li className="flex w-full flex-col">
                    <button
                        onClick={() => { setShowUsabilidade(!showUsabilidade); setTitle('Usabilidade'); }}
                        className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                    >
                        <BarChartIcon />
                        Usabilidade
                        <ChevronRightIcon style={{ transform: showUsabilidade ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                    </button>
                    {showUsabilidade && (
                        <ul className="pl-4 space-y-1">
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/usabilidade/geral'); setTitle('Usabilidade'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Geral
                                </button>
                            </li>
                            <li >
                                <button
                                    onClick={() => { navigate('/sistema/usabilidade/usuarios'); setTitle('Usabilidade Usuários'); }}
                                    className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                                >
                                    Usuários
                                </button>
                            </li>
                        </ul>
                    )}
                </li>

            </ul>
            </div>
        </Drawer>

        <div className="h-screen overflow-y-auto md:flex flex-col xs:hidden min-w-[200px] hidden bg-[#1f1f1f] no-scrollbar">
        <p className="text-white m-2 text-lg">Sistema - {appName}</p>
        <ul className="space-y-2">
            {/* Dashboard */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { navigate('/sistema'); setTitle('Painel sistema'); }}
                    className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <PieChartIcon />
                    Dashboard
                </button>
            </li>
            
            {/* Vendedor */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { navigate('/vendas'); setTitle('VENDEDOR'); }}
                    className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <MoneyIcon />
                    Realizar Venda
                </button>
            </li>

            {/* Vendas */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { navigate('/sistema/vendas'); setTitle('Vendas'); }}
                    className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <StoreIcon />
                    Vendas
                </button>
            </li>

            {/* Avisos */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { navigate('/sistema/avisos'); setTitle('Avisos'); }}
                    className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <NotificationIcon />
                    Avisos
                </button>
            </li>

            {/* Pedidos */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { navigate('/sistema/pedidos'); setTitle('Pedidos'); }}
                    className="flex items-center gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <LocalShippingIcon />
                    Pedidos
                </button>
            </li>

            <hr className="border-gray-700"/>

            {/* Configurações */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => setShowSettings(!showSettings)}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <WebIcon />
                    Configurações
                    <ChevronRightIcon style={{ transform: showSettings ? 'rotate(0deg)' : 'rotate(90deg)' }} />

                </button>
                {/* Dropdown de Configurações */}
                {showSettings && (
                    <ul className="pl-4 space-y-1">
                        <li >
                            <button 
                                onClick={() => { navigate('/sistema/painel-loja'); setTitle('Gerais'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                            >
                                Gerais
                            </button>
                        </li>
                        <li >
                            <button 
                                onClick={() => { navigate('/sistema/carrossel'); setTitle('Carrossel'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                            >
                                Carrossel
                            </button>
                        </li>
                        <li >
                            <button 
                                onClick={() => { navigate('/sistema/frete'); setTitle('Frete'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                            >
                                Frete
                            </button>
                        </li>
                        <li >
                            <button 
                                onClick={() => { navigate('/sistema/especificacoes-estoque'); setTitle('Configuração de estoque'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                            >
                                Configurar estoque
                            </button>
                        </li>
                    </ul>
                )}
            </li>

            {/* Produtos */}
            <li className="flex w-full flex-col">
                <button 
                    onClick={() => { setShowProdutos(!showProdutos); setTitle('Produtos'); }}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <ShoppingCartIcon />
                    Produtos
                    <ChevronRightIcon style={{ transform: showProdutos ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                </button>
                {showProdutos && (
                <ul className="pl-4 space-y-1">
                <li>
                    <button 
                        onClick={() => { navigate('/sistema/produtos'); setTitle('Ver Produtos'); }}
                        className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                    >
                        Ver Produtos
                    </button>
                </li>
                <li>
                    <button 
                        onClick={() => { navigate('/sistema/produto/novo'); setTitle('Novo Produto'); }}
                        className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                    >
                        Novo Produto
                    </button>
                </li>
                <li >
                    <button 
                        onClick={() => { navigate('/sistema/categorias'); setTitle('Categorias'); }}
                        className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                    >
                        Categorias
                    </button>
                </li>
                <li >
                    <button 
                        onClick={() => { navigate('/sistema/produtos/destaques'); setTitle('Destaques'); }}
                        className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                    >
                        Destaques
                    </button>
                </li>
                <li >
                    <button 
                        onClick={() => { navigate('/sistema/estoque-produto'); setTitle('Painel de Estoque'); }}
                        className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded"
                    >
                        Painel de Estoque
                    </button>
                </li>
                </ul>
                )}

            </li>

            {/* Clientes */}
            <li className="flex w-full flex-col">
            <button
                onClick={() => { setShowClientes(!showClientes); setTitle('Clientes'); }}
                className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
            >
                <PersonIcon />
                Clientes
                <ChevronRightIcon style={{ transform: showClientes ? 'rotate(0deg)' : 'rotate(90deg)' }} />
            </button>
            {showClientes && (
                <ul className="pl-4 space-y-1">
                    <li >
                        <button
                            onClick={() => { navigate('/sistema/usuario/novo'); setTitle('Novo Usuário'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                        >
                            Novo Usuário
                        </button>
                    </li>
                    <li >
                        <button
                            onClick={() => { navigate('/sistema/clientes'); setTitle('Clientes'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                        >
                            Ver Clientes
                        </button>
                    </li>
                    <li >
                        <button
                            onClick={() => { navigate('/sistema/clientes/orcamentos'); setTitle('Orçamentos'); }}
                            className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                        >
                            Orçamentos
                        </button>
                    </li>
                </ul>
            )}
            </li>

            {/* Integrações */}
            <li className="flex w-full flex-col">
                <button
                    onClick={() => { setShowIntegracoes(!showIntegracoes); setTitle('Integrações'); }}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <AccountTreeIcon />
                    Integrações
                    <ChevronRightIcon style={{ transform: showIntegracoes ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                </button>
                {showIntegracoes && (
                    <ul className="pl-4 space-y-1">
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/integracoes/mercado_pago'); setTitle('Mercado Pago'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Mercado Pago
                            </button>
                        </li>
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/integracoes/melhor_envio'); setTitle('Melhor Envio'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Melhor Envio
                            </button>
                        </li>
                    </ul>
                )}
            </li>

            {/* Campanhas */}
            <li className="flex w-full flex-col">
                <button
                    onClick={() => { setShowCampanhas(!showCampanhas); setTitle('Campanhas'); }}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <SendIcon />
                    Campanhas
                    <ChevronRightIcon style={{ transform: showCampanhas ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                </button>
                {showCampanhas && (
                    <ul className="pl-4 space-y-1">
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/campanhas/cupoms'); setTitle('Cupons Promocionais'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Cupons de Desconto
                            </button>
                        </li>
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/campanhas/leads'); setTitle('Painel de Leads'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Painel de Leads
                            </button>
                        </li>
                    </ul>
                )}
            </li>

            {/* Usabilidade */}
            <li className="flex w-full flex-col">
                <button
                    onClick={() => { setShowUsabilidade(!showUsabilidade); setTitle('Usabilidade'); }}
                    className="flex items-center justify-between w-full gap-2 text-white font-bold text-sm hover:bg-gray-700 p-2 rounded"
                >
                    <BarChartIcon />
                    Usabilidade
                    <ChevronRightIcon style={{ transform: showUsabilidade ? 'rotate(0deg)' : 'rotate(90deg)' }} />
                </button>
                {showUsabilidade && (
                    <ul className="pl-4 space-y-1">
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/usabilidade/geral'); setTitle('Usabilidade'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Geral
                            </button>
                        </li>
                        <li >
                            <button
                                onClick={() => { navigate('/sistema/usabilidade/usuarios'); setTitle('Usabilidade Usuários'); }}
                                className="text-white text-sm font-semibold hover:bg-gray-600 p-2 rounded w-full text-left"
                            >
                                Usuários
                            </button>
                        </li>
                    </ul>
                )}
            </li>
        </ul>
        </div>



        <div className="flex flex-col h-[100vh] flex-grow  text-white">
        {/* Barra de Navegação */}
        <div className="flex flex-col h-[100vh] flex-grow text-white">
        {/* Barra de Navegação */}
        <div className="h-[8vh] flex flex-row p-1">
            <div className="flex w-full justify-between mt-[1px]">
                <div className="flex">
                    {isSmScreen && (
                        <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                            {isDrawerOpen ? <CloseIcon style={{color: 'white'}}/> : <MenuIcon style={{color: 'white'}}/>}
                        </IconButton>
                    )}
                    <p className="font-[20px] ml-1 mt-2"><b>{title}</b></p>
                </div>
                {/* Menu de usuário */}
                <React.Fragment>
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Configurações de conta">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* Menu Suspenso - Dropdown */}
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 1,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        {/* Opção de Perfil */}
                        <MenuItem onClick={handleClose}>
                            <Avatar /> Perfil
                        </MenuItem>
                        <Divider />
                        {/* Opção de Logout */}
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Sair
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </div>
        </div>

        {/* Conteúdo Principal */}
        <div className="flex min-h-[92vh] overflow-y-scroll flex-col w-[100%] bg-white text-gray-900 p-2 rounded-lg gap-10">
            <Outlet />
        </div>
        </div>

        </div>

    </div> 
  );
};

export default NewPannel;