import React from 'react';
import Img from '../../assets/LOGO-BENI-ITALY.png'
function TermosDeUso() {
  return (
    <div
      className={
        "flex flex-col  p-4 sm:py-10 sm:px-20 justify-center align-middle items-center"
      }
    >
      <div className="flex max-w-[600px] flex-col border-1 border-yellow-600 rounded-md p-4 shadow-md text-[11pt]">
        <img src={Img} width={100} height={100} className="m-4 self-center" />
        <h3 className='text-[12pt] mt-2 font-extrabold text-gray-800'>Política de Cookies</h3>
        <p className="px-4">
          Utilizamos cookies para verificar a usabilidade de nossa aplicação e para usar
          em campanhas de publicidade divulgando condições, promoções e novidades.
          <br />
          Seus dados são mantidos dentro do app apenas para uso e funcionamento do mesmo, estão seguros e em posse única e exclusivamente da Beni Italy.
        </p>
        <p className="px-4">
          Continuar a usar nosso site implica em aceitar o uso de cookies conforme
          descrito. Se desejar mais informações sobre como protegemos seus dados,
          entre em contato conosco.
        </p>
      </div>
    </div>
  );
}

export default TermosDeUso;
